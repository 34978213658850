export enum TelehealthMDITheme {
  HRT = "HRT",
  Default = "Default",
}

export const getTelehealthMDITheme = (
  clinicianVendor: string
): TelehealthMDITheme => {
  if (clinicianVendor === "MD_INTEGRATIONS_OBGYN") {
    return TelehealthMDITheme.HRT;
  }

  return TelehealthMDITheme.Default;
};
