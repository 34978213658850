import getStore from "@utils/redux/store";
import { UserDataState } from "@utils/redux/slices/userData";
import { getDayDelta, toISO } from "@utils/datetime";
import { RecommendedPlanState } from "@utils/redux/slices/recommendedPlan";
import { getNoomPlanDurationFromID } from "@utils/plans";
import { BillingIntervalProto_Unit } from "@noom/noom-contracts/noom_contracts/billing/billing_interval";

type LeadTrackingProperties = {
  leadCaptureTime?: string;
  leadCaptureSinceDays?: number;
  forcedNoomPlanId?: string;
  // TODO(noom events): remove non-suffixed version when no longer sending to mixpanel.
  forcedPlanDuration?: number;
  forcedPlanDurationMonth?: number;
  forcedPlanDurationUnits?: BillingIntervalProto_Unit;
};

function leadCapture(userData?: UserDataState) {
  const { leadCaptureDateISO } = userData || {};
  if (!leadCaptureDateISO) {
    return {};
  }

  return {
    leadCaptureTime: toISO(leadCaptureDateISO),
    leadCaptureSinceDays: getDayDelta(new Date(leadCaptureDateISO), new Date()),
  };
}

function forcedPlanProperties(recommendedPlan?: RecommendedPlanState) {
  if (!recommendedPlan || !recommendedPlan.isForcedPlan) {
    return {};
  }
  return {
    forcedNoomPlanId: recommendedPlan.noom_plan_id,
    forcedPlanDuration:
      recommendedPlan.billing_cycle_duration ||
      getNoomPlanDurationFromID(recommendedPlan.noom_plan_id),
    forcedPlanDurationUnits: recommendedPlan.billing_cycle_duration_units,
  };
}

export function getLeadTrackingProperties(): LeadTrackingProperties {
  let userData;
  let recommendedPlan;

  // Make sure we don't fail if this is called before redux is init.
  try {
    ({ userData, recommendedPlan } = getStore().getState());
  } catch (e) {
    /* NOP */
  }

  return {
    ...leadCapture(userData),
    ...forcedPlanProperties(recommendedPlan),
  };
}
