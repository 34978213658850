import { getPageSetFromPath } from "@pageDefinitions/pageSets";
import { findEntryPointLocation } from "src/pageDefinitions/actions/survey/util";
import getStore from "src/utils/redux/store";
import { gotoPage } from "./pageSet";
import { getHistory } from "../components/PageSetRouter";
import {
  getMedRecommendedPlanTier,
  getHRTRecommendedPlanTier,
} from "src/components/med/planInformation/utils";
import { MED_SKU, MED_FLOW } from "@utils/medEligibility";
import goto from ".";

export const medSkuEntryPoints = {
  [MED_SKU.ORALS_METFORMIN]: "medOralsPlan",
  [MED_SKU.COMPOUNDED]: "medCompoundedPlan",
  [MED_SKU.BRANDED]: "medBrandedPlan",
  [MED_SKU.BRANDED_ZEPBOUND_VIAL]: "medBrandedZepboundVialPlan",
  [MED_SKU.HRT_CREAM]: "hrtCreamPlan",
  [MED_SKU.HRT_PATCH]: "hrtPatchPlan",
};

export default {
  async navigateToPlanRevealPage(flow: string) {
    const store = getStore();
    const history = getHistory();
    const pageSet = await getPageSetFromPath(
      window.location.pathname,
      getStore().getState()
    );

    const recommendedMedTier =
      flow === MED_FLOW.HRT
        ? getHRTRecommendedPlanTier()
        : getMedRecommendedPlanTier();
    const entryPoint = medSkuEntryPoints[recommendedMedTier];

    if (flow === MED_FLOW.CLINICAL && !entryPoint) {
      return goto.bail(
        "mainSurvey",
        [],
        "No entry point found for med tier payment results"
      );
    }

    const entryPointLocation = findEntryPointLocation(pageSet, entryPoint);
    return gotoPage(pageSet, { store, history }, false, entryPointLocation);
  },
};
