import { ActionHandler } from "..";
import saveToBraze from "@utils/brazeUploader";
import { getSurveyAnswers } from "src/hooks/survey/answers";

export const onHRTLeadCaptured: ActionHandler = () => {
  const { menopauseSymptoms } = getSurveyAnswers();

  saveToBraze({
    event: {
      event_name: "HRTLeadCaptured",
      event_data: {
        menopauseSymptoms,
      },
    },
  });
};
