import { Plan } from "src/utils/redux/slices/plans";
import { send } from "src/utils/fetch";
import { shift_days, toISO } from "src/utils/datetime";
import { getCountryCode, getLocale } from "src/utils/meristemContext";
import { genderConstants, sexConstants } from "src/utils/sexAndGender";
import { saveSurveyAnswers } from "src/utils/redux/slices/userData";
import { MenopauseSymptoms } from "@components/hrt/utils/menopauseSymptoms";
import { authenticateUser } from "./deepLinksUtils";

type SaveSurveyAnswerParams = Parameters<typeof saveSurveyAnswers>;

export const getPayloadForMedUser = (
  email: string
): {
  surveyAnswers: SaveSurveyAnswerParams[0];
  params: SaveSurveyAnswerParams[1];
} => ({
  surveyAnswers: {
    heightFeet: 5,
    heightInch: 5,
    weight: 300,
    heightCm: 165.1,
    weightKg: 90.70294784580499,
    currentBMI: 33.27810650887574,
    idealWeight: 170,
    idealWeightKg: 77.09750566893423,
    targetBMI: 22.4,
    sex: [sexConstants.female],
    gender: [genderConstants.female],
    pregnant: ["no"],
    ageRange: ["30s"],
    medOptIn: ["yes"],
    medicare: ["no"],
    currentHealthRiskMedical: ["none", { other: "abcd" }],
    zipcode: "98118",
    email,
  },
  params: {
    surveyNameSpace: "personaSurveyUS",
    overwrite: true,
    extraProps: {
      name: "",
      clientTimestamp: "2021-09-06T19:27:21.855Z[Europe/Bucharest]",
      timezone: "Europe/Bucharest",
      isEUCitizen: false,
      mainSurveyCompleteDateISO: "2021-09-06T16:27:21.855Z",
      email,
      latestMixPanelAlias: email,
      language: "en",
      lastUpdated: 1630945641855,
    },
  },
});

export const getPayloadForGlp1CompanionUser = (
  email: string
): {
  surveyAnswers: SaveSurveyAnswerParams[0];
  params: SaveSurveyAnswerParams[1];
} => ({
  surveyAnswers: {
    basicHealthGoal: ["fit"],
    heightFeet: 5,
    heightInch: 5,
    weight: 300,
    heightCm: 165.1,
    weightKg: 90.70294784580499,
    currentBMI: 33.27810650887574,
    idealWeight: 170,
    idealWeightKg: 77.09750566893423,
    targetBMI: 22.4,
    sex: [sexConstants.female],
    gender: [genderConstants.female],
    pregnant: ["no"],
    ageRange: ["60s"],
    noomMoodBundle: ["yes"],
    glp1: ["yes"],
    glpCompanionAdded: true,
    email,
  },
  params: {
    surveyNameSpace: "personaSurveyUS",
    overwrite: true,
    extraProps: {
      name: "",
      clientTimestamp: "2021-09-06T19:27:21.855Z[Europe/Bucharest]",
      timezone: "Europe/Bucharest",
      isEUCitizen: false,
      mainSurveyCompleteDateISO: "2021-09-06T16:27:21.855Z",
      email,
      latestMixPanelAlias: email,
      language: "en",
      lastUpdated: 1630945641855,
    },
  },
});

export const getPayloadForMhtUser = (
  email: string
): {
  personaSurveyAnswers: SaveSurveyAnswerParams[0];
  paymentSurveyAnswers: SaveSurveyAnswerParams[0];
} => ({
  personaSurveyAnswers: {
    basicHealthGoal: ["fit"],
    heightFeet: 5,
    heightInch: 5,
    weight: 200,
    heightCm: 165.1,
    weightKg: 90.70294784580499,
    currentBMI: 33.27810650887574,
    idealWeight: 170,
    idealWeightKg: 77.09750566893423,
    targetBMI: 22.4,
    sex: [sexConstants.female],
    gender: [genderConstants.female],
    pregnant: ["no"],
    ageRange: ["50s"],
    ageYears: 50,
    zipcode: "10001",
    currentHealthRiskHRT: ["none"],
    email,
  },
  paymentSurveyAnswers: {
    menstrualCycle: ["no"],
    lastPeriod: ["from1To2Years"],
    periodEndReason: ["menopause"],
    menopauseSymptoms: [
      MenopauseSymptoms.HOT_FLASHES,
      MenopauseSymptoms.NIGHT_SWEATS,
    ],
  },
});

export const getPayloadForHRTCheckout = ({
  planType,
}: {
  planType: "cream" | "patch";
}): {
  hrtSurveyAnswers: SaveSurveyAnswerParams[0];
} => ({
  hrtSurveyAnswers: {
    topMenopauseSymptom: [MenopauseSymptoms.HOT_FLASHES],
    takingHRT: ["yes"],
    currentHRTTreatment:
      planType === "cream" ? ["hormonalCreamOrGel"] : ["estrogenPatch"],
    currentHRTPreference: ["yes"],
  },
});

export const getPayloadForCreateUser = (
  email: string,
  payloadOverrides?: Record<string, any>
) => ({
  name: "",
  clientTimestamp: "2021-09-06T19:27:21.855Z[Europe/Bucharest]",
  timezone: "Europe/Bucharest",
  isEUCitizen: false,
  mainSurveyCompleteDateISO: "2021-09-06T16:27:21.855Z",
  surveyNameSpace: "personaSurveyUS",
  personaSurveyUS: {
    basicHealthGoal: ["fit"],
    heightFeet: 5,
    heightInch: 5,
    weight: 200,
    heightCm: 165.1,
    weightKg: 90.70294784580499,
    currentBMI: 33.27810650887574,
    idealWeight: 170,
    idealWeightKg: 77.09750566893423,
    targetBMI: 22.4,
    sex: ["female"],
    gender: ["female"],
    pregnant: ["no"],
    ageRange: ["30s"],
    email,
    ...payloadOverrides,
  },
  email,
  latestMixPanelAlias: email,
  language: "en",
  country: "US",
  subdivision: "NY",
  postalCode: "11375",
  lastUpdated: 1630945641855,
});

const getPayloadForEnrollUser = (
  email: string,
  nuid: string,
  password: string,
  payloadOverrides?: Record<string, any>
) => ({
  userProfileData: {
    email,
    name: "Mike Jones",
    password,
    phoneNumber: "8053308004",
    language: "en",
    gender: "FEMALE",
    heightInCm: 167.64000000000001,
    weightInKg: 70.29478458049887,
    age: 30,
    ...payloadOverrides,
  },
  action: "create_account",
  name: "Mike Jones",
  email,
  password,
  phone_number: "8053308004",
  is_eu_citizen: false,
  gdpr_consent:
    '{"granted": true, "source": "AUTO", "time": "2021-09-07T09:57:55.967Z[Europe/Bucharest]"}',
  country_code: "US",
  language: "en",
  user_id: nuid,
  experiments: [],
  device_is_mobile: false,
});

const getGrowthProgramDates = (plan: Plan) => {
  const growthProgramStartDateISO = toISO();
  const expirationDate = shift_days(plan.trial_duration, new Date());
  const growthTrialEndDate = expirationDate.toLocaleDateString(getLocale());
  const growthTrialEndDateISO = toISO(expirationDate);
  return {
    growthProgramStartDateISO,
    growthTrialEndDate,
    growthTrialEndDateISO,
  };
};

const getPayloadForPurchaseRequest = (
  email: string,
  plan: Plan,
  purchaseOverrides?: Record<string, any>
) => ({
  name: "Mike Jones",
  email,
  countryCode: getCountryCode(),
  continentCode: "NA",
  noomPlanId: plan.noom_plan_id,
  braintreeId: plan.braintree_id,
  merchantAccount: plan.merchant_account,
  language: "en",
  curriculum: plan.curriculum ?? "HW",
  inAppPurchase: false,
  paymentMethodNonce: "pm_card_bypassPending",
  paymentMethodType: "CreditCard",
  plan,
  paymentProcessor: "STRIPE",
  zipcode: "10001",
  recaptchaToken: "dummy_recaptcha_token_not_needed",
  billingAddress: {
    country: "US",
    city: "NY",
    region: "New York",
    address1: "123 peace street",
    address2: "",
    zipcode: "10001",
    name: "Mike Jones",
  },
  one_source_tax_code: "S001",
  shouldEnroll: true,
  experiments: [],
  trialFee: plan.trial_item_options[0]?.price,
  trialSaleItemId: plan.trial_item_options[0]?.trial_sale_item_id,
  subscriptionSaleItemId: plan.sale_item_id,
  ...getGrowthProgramDates(plan),
  ...purchaseOverrides,
});

const getPayloadForPurchaseNoomPremium = (
  email: string,
  user_id: string,
  noomPremiumId: string
) => ({
  email,
  currencySymbol: "$",
  language: "en",
  shouldValidateAccessToken: false,
  user_id,
  productIds: [noomPremiumId],
  alwaysUseInclusiveTaxes: false,
});

export const createUserInGrowthDB = (
  email: string,
  payloadForCreateUserOverrides?: Record<string, any>
) => {
  const payload = getPayloadForCreateUser(email, payloadForCreateUserOverrides);
  return send("POST", "/userdata/api/v4/user/data/saveMainSurvey/", payload);
};

export const purchaseNoomPremium = (
  email: string,
  user_id: string,
  noomPremiumId: string
) => {
  const payload = getPayloadForPurchaseNoomPremium(
    email,
    user_id,
    noomPremiumId
  );
  return send("POST", "/api/payment/v2/purchase_add_on/", payload);
};

export type PayloadForPurchaseOverrides = {
  purchaseOverrides?: Record<string, any>;
  createUserOverrides?: Record<string, any>;
  enrollUserOverrides?: Record<string, any>;
};

export const makePurchase = (
  email: string,
  password: string,
  plan: Plan,
  updateStatus: (string) => void,
  payloadOverrides?: PayloadForPurchaseOverrides
) => {
  const createUser = () => {
    updateStatus("Creating user...");
    return createUserInGrowthDB(email, payloadOverrides?.createUserOverrides);
  };

  const enrollUser = (createUserResponse) => {
    updateStatus("Enrolling user...");
    const payload = getPayloadForEnrollUser(
      email,
      createUserResponse.user_id,
      password,
      payloadOverrides?.enrollUserOverrides
    );
    return send("POST", "/api/enrollment/v2/create_account/", payload);
  };

  const authenticate = () => {
    updateStatus("Authenticating user...");
    return authenticateUser(email, password);
  };

  const sendPurchaseRequest = () => {
    updateStatus("Purchasing plan...");
    const payload = getPayloadForPurchaseRequest(
      email,
      plan,
      payloadOverrides?.purchaseOverrides
    );
    return send("POST", "/api/payment/v2/purchase_program/", payload);
  };

  return createUser()
    .then(enrollUser)
    .then(authenticate)
    .then(sendPurchaseRequest)
    .catch((err) => {
      console.log(err);
      console.log(err.response);
      throw err;
    });
};
