import { SurveyAnswersState } from "@utils/redux/slices/surveyAnswers";
import { RecommendedPlanState } from "../redux/slices/recommendedPlan";
import getStore from "@utils/redux/store";
import { initializeHandlers, firePixelEvent } from "../pixels/handler";
import { getPromoCodeState } from "../redux/slices/promoCode";
import { getSurveyAnswers } from "src/hooks/survey/answers";
import { PurchaseProgramResponse } from "../api/purchase/checkout";
import {
  shouldFireMedPixel,
  userHasPurchasedNoomClinical,
} from "../userSegment/features";
import { MedProductType } from "../constants";

export type PurchaseIntentDetails = {
  estimatedValue: number;
  medProductType: MedProductType;
  customEventName: string;
};

export function initConversionTracker() {
  initializeHandlers();
  firePixelEvent("init", {});
}

/**
 * Tracks the 'Lead' event.
 */
export function trackLead(email: string, surveyAnswers?: SurveyAnswersState) {
  const { userData } = getStore().getState();
  firePixelEvent("lead", { email, userData, surveyAnswers });
}

/**
 * Tracks the 'Purchase' event.
 */
export async function trackPurchase(
  email: string,
  plan: RecommendedPlanState,
  surveyAnswers: SurveyAnswersState,
  order: PurchaseProgramResponse
) {
  const { userData } = getStore().getState();
  const promoCode = getPromoCodeState();
  firePixelEvent("purchase", {
    email,
    plan,
    surveyAnswers,
    order,
    userData,
    promoCode,
  });
}

/**
 * Tracks an event that is corelated with a purchase decision
 * this is used in cases where we can't track the purchase itself
 */
export function trackPurchaseIntent(
  email: string,
  plan: RecommendedPlanState,
  surveyAnswers: SurveyAnswersState,
  purchaseIntentDetails: PurchaseIntentDetails
) {
  const { userData } = getStore().getState();
  firePixelEvent("purchaseIntent", {
    email,
    plan,
    surveyAnswers,
    userData,
    estimatedValue: purchaseIntentDetails.estimatedValue,
    medProductType: purchaseIntentDetails.medProductType,
    customEventName: purchaseIntentDetails.customEventName,
  });
}

export function trackViewContent() {
  const { userData } = getStore().getState();
  firePixelEvent("viewContent", {
    value: 2.0,
    currency: "USD",
    userId: userData.user_id,
  });
}

// TODO(george): Add a better way to segment users that purchased med if we add post-signup purchases
// to the med flow
export function trackFastForwardTrial({
  discountOffered,
}: {
  discountOffered: boolean;
}) {
  const { userData } = getStore().getState();
  const surveyAnswers = getSurveyAnswers();
  if (shouldFireMedPixel()) {
    return Promise.resolve();
  }
  return firePixelEvent("trialFastForward", {
    discountOffered,
    email: userData.email || surveyAnswers.email || "",
    userData,
  });
}

export function trackNoomPremium(signupEltv13: number, products: string[]) {
  if (userHasPurchasedNoomClinical() || shouldFireMedPixel()) {
    return Promise.resolve();
  }
  const { userData } = getStore().getState();
  const surveyAnswers = getSurveyAnswers();
  return firePixelEvent("noomPremium", {
    signupEltv13,
    products,
    email: userData.email || surveyAnswers.email || "",
    userData,
  });
}

export function trackNoomSummit(signupEltv13: number, products: string[]) {
  if (shouldFireMedPixel()) {
    return Promise.resolve();
  }
  return firePixelEvent("noomSummit", {
    signupEltv13,
    products,
  });
}
