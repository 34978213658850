import { getSessionState, updateSessionState } from "@pageDefinitions/session";
import { isEligibleForCoachReferralBuyflow } from "./userSegment/features";

type CoachReferralInfo = {
  referralCode: string;
  coachName: string;
  coachImage: string;
  acceptingNewUsers: boolean;
};

declare module "src/pageDefinitions/session" {
  interface BrowserSessionState {
    referralInfo?: CoachReferralInfo;
  }
}

const REFERRAL_INFO_STORAGE_KEY = "coachReferralInfo";
const REFERRAL_PROMO_CODE = "REFERRAL10";

export function setCoachReferralInfo(referralInfo: CoachReferralInfo) {
  updateSessionState("browser", {
    referralInfo,
  });
  localStorage.setItem(REFERRAL_INFO_STORAGE_KEY, JSON.stringify(referralInfo));
}

export function getCoachReferralInfo(): CoachReferralInfo {
  const { referralInfo } = getSessionState("browser");
  if (referralInfo) {
    return referralInfo;
  }

  const localStorageInfo = localStorage.getItem(REFERRAL_INFO_STORAGE_KEY);
  if (localStorageInfo) {
    return JSON.parse(localStorageInfo);
  }

  return null;
}

export function getCoachReferralPromoCode() {
  if (isEligibleForCoachReferralBuyflow()) {
    return REFERRAL_PROMO_CODE;
  }
  return null;
}
