type MessageHandler<T extends JsonObject> = Readonly<{
  postMessage: (payload: T) => void;
}>;

type EmptyObject = Record<string, never>;

export type NativePurchaseInfo = {
  productData: {
    productId: string;
    subscriptionLength: number;
  };
  programAvailability: {
    curriculum: string;
    isAvailable: boolean;
    language: string;
  };
  tracking: {
    routeId: string;
    tier: string;
  };
};

export type DynamicSurveyAnswer = {
  questionId: string;
  answerSlugs?: string[];
  answerText?: string;
};

export type DynamicSurveyCompletedPayload = {
  surveysCompleted: {
    surveyId: string;
    surveyExperienceSlug: string;
    surveyAnswers: DynamicSurveyAnswer[];
  }[];
};

export type IOSMessageHandlers =
  | Readonly<{
      programSwitchComplete?: MessageHandler<EmptyObject>;

      buyflowExit?: MessageHandler<EmptyObject>;

      webviewExit?: MessageHandler<EmptyObject>;

      /**
       * The IOS webview client has a backPrevented variable that controls whether we show a back button or not.
       * This message handler acts as a setter for that variable
       *
       */
      webviewPreventsGoingBack?: MessageHandler<{ backPrevented: boolean }>;

      buyflowPurchase?: MessageHandler<EmptyObject>;

      buyflowPurchaseProduct?: MessageHandler<NativePurchaseInfo>;

      buyflowPurchaseNoDetails?: MessageHandler<{
        forceExperimentsToAssign?: {
          name: string;
          variation: string;
        }[];
      }>;

      buyflowPurchaseCompleted?: MessageHandler<EmptyObject>;
      freemiumBuyflowComplete?: MessageHandler<EmptyObject>;
      checkInModuleSelection?: MessageHandler<string>;
      checkInSurveyCompleted?: MessageHandler<DynamicSurveyCompletedPayload>;
    }>
  | undefined;

export type IOSTelehealthMessageHandlers =
  | Readonly<{
      taskComplete?: MessageHandler<EmptyObject>;

      taskSkipped?: MessageHandler<EmptyObject>;
    }>
  | undefined;

export function getIOSMessageHandlers() {
  return (window as any).webkit?.messageHandlers as IOSMessageHandlers;
}

export function getIOSTelehealthMessageHandlers() {
  return (window as any).webkit
    ?.messageHandlers as IOSTelehealthMessageHandlers;
}

export type AndroidMessageHandlers =
  | Readonly<{
      buyflowExit?: () => void;
      webviewExit?: () => void;
      programSwitchComplete?: () => void;
      trackPurchaseCompleted?: (payload: string) => void;
      buyflowPurchase?: () => void;
      buyflowPurchaseCompleted?: () => void;
      buyflowPurchaseProduct?: (payload: string) => void; // JSON string of NativePurchaseInfo object
    }>
  | undefined;

export type AndroidTelehealthMessageHandlers =
  | Readonly<{
      taskComplete?: () => void;
      taskSkipped?: () => void;
    }>
  | undefined;

export function getAndroidMessageHandlers() {
  return (window as any).Android as AndroidMessageHandlers;
}

export function getAndroidTelehealthMessageHandlers() {
  return (window as any).AndroidTelehealth as AndroidTelehealthMessageHandlers;
}
