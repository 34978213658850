import Cookies from "js-cookie";
import { send } from "@utils/fetch";
import type { AppDispatch, GetAppState } from "@utils/redux/store";
import getStore from "@utils/redux/store";
import { captureException } from "@utils/error";
import isEmpty from "lodash/isEmpty";
import {
  isEU,
  isFreemiumUpsellRoute,
  isFromEmail,
  isUS,
} from "@utils/userSegment";
import { prepareDataForBackend } from "@utils/pace";
import { setSurveyNameSpace } from "./surveyNameSpace";
import { addSurveyAnswers, SurveyAnswersState } from "./surveyAnswers";
import { updateVisitorStatusState } from "./visitorStatus";
import { timestamp, timezone, toISO } from "src/utils/datetime";
import { ServerContextState } from "./serverContext";
import getApiDomain from "src/utils/services/api-params";
import {
  isDevMode,
  isInAppWebPurchaseEligible,
} from "src/utils/userSegment/features";
import {
  getCountryCode,
  getLanguage,
  getMeristemContext,
  getRequestMetadata,
  getRouteId,
  getSubdivision,
} from "@utils/meristemContext";
import { logInfo } from "src/utils/monitoring/logging";
import { getSurveyAnswers } from "src/hooks/survey/answers";
import type { GameStateJSON as FoodieGameState } from "src/free-products/foodie";
import {
  getSessionState,
  updateSessionState,
} from "src/pageDefinitions/session";
import { USER_IS_BOT } from "src/utils/botDetector";
import { trackTask } from "src/utils/monitoring/events/tasks";
import { NoomTogetherState } from "./noomTogether";
import { handleHttpForbidden } from "./authStatus";
import { BillingIntervalProto_Unit } from "@noom/noom-contracts/noom_contracts/billing/billing_interval";

export type UserDataState = {
  targetWeightInKg?: number;
  user_id?: string;
  name?: string;
  email?: string;
  country?: string;

  mainSurveyCompleteDateISO?: string;
  leadCaptureDateISO?: string;
  premiumUpgradeSource?: string;
  NoomPremiumSurveyCompleteDateISO?: string;
  // Tracks the initial route that the user first arrived at Noom on. DO
  // NOT USE TO DETERMINE ROUTE. For that, use getRouteId() instead.
  initialRouteId?: string;

  vip?: boolean;

  weight?: number;
  heightFeet?: number;
  heightInch?: number;

  // Enum of surveys below
  surveyNameSpace?:
    | "personaSurveyAnswers"
    | "personaSurveyUS"
    | "personaSurveyNonUS"
    | "personaSurveyHM"
    | "purchaseSurveyAnswers"
    | "purchaseSurveyAnswersHM";

  personaSurveyAnswers?: SurveyAnswersState;
  personaSurveyUS?: SurveyAnswersState;
  personaSurveyNonUS?: SurveyAnswersState;
  personaSurveyHM?: SurveyAnswersState;

  purchaseSurveyAnswers?: SurveyAnswersState;
  purchaseSurveyAnswersHM?: SurveyAnswersState;

  summitSurvey?: SurveyAnswersState;
  hrtSurvey?: SurveyAnswersState;

  checkoutPersonalizedPlanTimerExpirationTimestamp?: number | false;
  checkoutDiscountedMWCPTimerExpirationTimestamp?: number | false;
  initialExpirationDurationForFreeMWCP?: number;
  answerToFreeMWCPModal?: string;

  zip_tax_state?: string;

  language?: string;

  growthPlanDuration?: number;
  growthPlanDurationUnits?: BillingIntervalProto_Unit;

  access_code?: string;
  last_login_device_OS?: string;

  force_merge?: boolean;
  upsell?: {
    products: string[];
  };

  growthTrialEndDateISO?: string;
  growthProgramStartDateISO?: string;
  foodieGameState?: FoodieGameState;

  eltv_13_months?: number;

  targetWeightDate?: string;

  // ex1099 Afterpay Integration (Fulfilled)
  growthPaymentMethodType?: string;

  growthPaymentSource?: string;

  noomTogetherState?: NoomTogetherState;

  billing_reminder?: { optIn?: boolean; trialOptIn?: boolean };

  // ups_ex233 enrollment flag to track users across multiple visits
  // DDoc: https://docs.google.com/document/d/1LvIN858g78zYT7MjmLm7WJ9Yfc3yoV3WDIcdaLl_a9I
  // TODO(james): Remove this once ups_ex233 is complete
  ups_ex233?: boolean;

  purchasedNoomClinical?: boolean;

  usedBaselinePriceForMultiUserPlan?: boolean;
  phone_number?: string;
  phone_number_country_code?: string;
  mixpanel_distinct_id?: string;
  inAppName?: string;
  traakrOrderIds?: string[];

  indicatedZepboundVialPreference?: boolean;
};

export type UserDataStateWithPhoneNumber = UserDataState & {
  phone_number?: string;
};

declare module "src/pageDefinitions/session" {
  interface BrowserSessionState {
    userDataExpected?: boolean;
  }
}

/**
 * Determines if the current user is likely to have stored user data in the
 * database. This is intended to reduce the amount of load on our servers
 * from high-volume, but low conversion, traffic on the landing pages.
 */
export function expectUserData() {
  // Guard against bots, don't attempt to fetch userData if we detect a bot.
  if (USER_IS_BOT) {
    return false;
  }

  // Assume that a user exists if the user is in a context that should have
  // previously collected and saved user data.
  if (
    !["landing", "main-survey"].includes(getMeristemContext().context_type) ||
    isFreemiumUpsellRoute()
  ) {
    return true;
  }

  const { userState } = getRequestMetadata();
  let { userDataExpected } = getSessionState("browser");

  if (userDataExpected == null) {
    // Store the newUser flag in the local session as the next request
    // to BFW will have the cookie set and BFW will not be able to determine
    // if the user is new or not.
    userDataExpected = !userState.newUser;
    updateSessionState("browser", { userDataExpected });
  }

  return userDataExpected;
}

/**
 * Action types
 */
export const UPDATE_USER_DATA = "userData/updateUserData";

interface UserDataAction {
  type: typeof UPDATE_USER_DATA;
  userData: Partial<UserDataState>;
}

/**
 * Action
 * Updates the store with new userdata
 */
export function updateUserData(userData: UserDataState): UserDataAction {
  return {
    type: UPDATE_USER_DATA,
    userData,
  };
}

/**
 * The Django API uses the `_userId` cookie for some purposes. This utility helps
 * us set the cookie whenever its updated.
 */
export function setUserIdCookie(userId: string) {
  if (!userId) {
    return;
  }

  const cookiedUserId = Cookies.get("_userId");
  if (cookiedUserId !== userId) {
    // WARN: Global mutation.
    const { userState } = getRequestMetadata();
    userState.userId = userId;
    userState.newUser = false;

    Cookies.set("_userId", userId, {
      domain:
        process.env.NODE_ENV !== "production" ? undefined : getApiDomain(),
    });
  }
}

/**
 * Thunk
 */
export function fetchAndInitializeExistingUser(userId: string) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await fetchExistingUser(userId);
      await dispatch(initializeExistingUser(response));
    } catch (e) {
      if (e?.response?.status === 403 || e?.response?.status === 401) {
        dispatch(handleHttpForbidden());
      } else {
        captureException(e, "UserDataLoad-Failed to fetch");
      }
    }
  };
}

export async function fetchExistingUser(userId: string) {
  logInfo(`loading => users/${userId}`);
  const json = await send("POST", `/userdata/api/v4/user/data/`, {
    clientUserId: userId,
  });
  // Make sure the user has a userId field set in their userData.
  const userData: UserDataState = { ...json.data };

  const mainSurveyNameSpace = isUS() ? "personaSurveyUS" : "personaSurveyNonUS";
  if (
    userData[mainSurveyNameSpace]?.importantDateTime &&
    !userData[mainSurveyNameSpace]?.importantDateTimeISO
  ) {
    userData[mainSurveyNameSpace].importantDateTimeISO = new Date(
      userData[mainSurveyNameSpace].importantDateTime
    ).toISOString();
  }

  if (!userData.user_id) {
    userData.user_id = userId;
  }
  return userData;
}

export function initializeExistingUser(userData: UserDataState) {
  return async (dispatch: AppDispatch) => {
    updateSessionState("browser", { userDataExpected: true });
    dispatch(updateUserData(userData));

    setUserIdCookie(userData.user_id);
    const mainSurveyNameSpace =
      userData?.surveyNameSpace ||
      (isUS() ? "personaSurveyUS" : "personaSurveyNonUS");
    const backendMainSurveyAnswers = userData?.[mainSurveyNameSpace] || {};

    dispatch(setSurveyNameSpace(mainSurveyNameSpace));
    if (!isEmpty(backendMainSurveyAnswers)) {
      dispatch(addSurveyAnswers(backendMainSurveyAnswers));
    }
  };
}

export function hasValidAccount({ hasValidEmail }: { hasValidEmail: boolean }) {
  // Depends only on statics (geo, params) so this is safe even if we aren't yet initialized
  const allowInAppPurchase = isInAppWebPurchaseEligible();
  const isAllowedThrough = hasValidEmail || isDevMode() || allowInAppPurchase;
  return isAllowedThrough;
}

/**
 * Thunk
 */
export function initializeNewUser(serverContext: ServerContextState) {
  return (dispatch: AppDispatch) => {
    const data = {
      user_id: serverContext.user_id,
    };
    dispatch(updateUserData(data));

    const nameSpace = isUS() ? "personaSurveyUS" : "personaSurveyNonUS";
    dispatch(setSurveyNameSpace(nameSpace));

    return Promise.resolve(data);
  };
}

/**
 * Should handle cookie lifecycle on the backend when the user is changed.
 * Backend should handle data movement between the 2 users. (e.g. visits)
 *
 */
export function changeUser(toUserId: string) {
  return async (dispatch: AppDispatch, getState: GetAppState) => {
    const { serverContext } = getState();
    const payload = {
      to_user_id: toUserId,
    };
    const fromUserId = serverContext.user_id;
    const task = trackTask("ChangingUser", {
      fromId: fromUserId,
      toId: toUserId,
    });
    task.start();

    logInfo(`Changing user from ${fromUserId} to ${toUserId}`);
    try {
      const { data: userData } = await send(
        "POST",
        "/userdata/api/v4/user/change/",
        payload
      );
      await dispatch(initializeExistingUser(userData));
      task.success();
    } catch (err) {
      task.failed(err);
    }
  };
}

/**
 * Thunk
 * Generic save user data call, updates entire userdata object.
 */
export function setUserProperties(properties: Partial<UserDataState>) {
  return (dispatch: AppDispatch, getState: GetAppState) => {
    return submitUserDataUpdate(dispatch, getState, "save", properties);
  };
}

/**
 * Thunk
 * Updates the main survey answers.
 */
export function saveMainSurveyAnswers(
  surveyAnswers: SurveyAnswersState,
  {
    surveyNameSpace,
    dateKey = "mainSurveyCompleteDateISO",
    extraProps,
  }: {
    surveyNameSpace: keyof UserDataState;
    dateKey?: string;
    extraProps?: JsonObject;
  }
) {
  return (dispatch: AppDispatch, getState: GetAppState) => {
    const state = getState();
    const timezoneName = timezone();
    const timestampString = `${timestamp()}[${timezone()}]`;

    const finalSurveyAnswers = {
      ...(state.userData[surveyNameSpace] as SurveyAnswersState),
      ...surveyAnswers,
    };

    const properties: JsonObject = {
      name:
        surveyAnswers.name ||
        state.userData.name ||
        surveyAnswers.welcomeName ||
        "",

      clientTimestamp: timestampString,
      timezone: timezoneName,
      isEUCitizen: isEU(),
      [dateKey]: toISO(),

      surveyNameSpace,
      [surveyNameSpace]: finalSurveyAnswers,
      ...extraProps,

      language: getLanguage(),
      country: getCountryCode(),
      subdivision: getSubdivision(),
      postalCode: getMeristemContext().postal_code,
    };

    // NOTE(patrick): If we pass an empty string email to the back end, userdata merge
    //                will throw a 500 error.
    const email = surveyAnswers.email || "";
    if (email) {
      properties.email = email;
      properties.latestMixPanelAlias = email;
    }

    if (
      !state.userData.leadCaptureDateISO ||
      (email && email !== state.userData.email)
    ) {
      properties.leadCaptureDateISO = toISO();
    }

    if (surveyAnswers.gdprConsent != null) {
      properties.consent = surveyAnswers.gdprConsent;
    }

    properties.routeId = getRouteId();

    // Set the initial route ID, but only once or on email change.
    if (
      !state.userData.initialRouteId ||
      (email && email !== state.userData.email)
    ) {
      properties.initialRouteId = properties.routeId;
    }

    return submitUserDataUpdate(
      dispatch,
      getState,
      email ? "saveMainSurvey" : "save",
      properties
    ).then(() => finalSurveyAnswers);
  };
}

export function addMainSurveyAnswers(surveyAnswers: SurveyAnswersState) {
  const { surveyNameSpace } = getStore().getState();
  return saveMainSurveyAnswers(surveyAnswers, { surveyNameSpace });
}

export function addPurchaseSurveyAnswers(surveyAnswers: SurveyAnswersState) {
  const { userData } = getStore().getState();
  const totalSurveyAnswers = {
    ...userData.purchaseSurveyAnswers,
    ...surveyAnswers,
  };
  return setPurchaseSurveyAnswers(totalSurveyAnswers);
}

export function setPurchaseSurveyAnswers(surveyAnswers: SurveyAnswersState) {
  return saveSurveyAnswers(surveyAnswers, {
    surveyNameSpace: "purchaseSurveyAnswers",
    overwrite: true,
    dateKey: "purchaseSurveyCompleteDateISO",
  });
}

export function setHRTSurveyAnswers(surveyAnswers: SurveyAnswersState) {
  return saveSurveyAnswers(surveyAnswers, {
    surveyNameSpace: "hrtSurvey",
    overwrite: true,
  });
}

export function saveSurveyAnswers(
  surveyAnswers: SurveyAnswersState,
  {
    surveyNameSpace,
    overwrite,
    dateKey,
    extraProps,
  }: {
    surveyNameSpace: string;
    overwrite: boolean;
    dateKey?: string;
    extraProps?: JsonObject;
  }
) {
  return (dispatch: AppDispatch, getState: GetAppState) => {
    const state = getState();
    const finalSurveyAnswers = overwrite
      ? surveyAnswers
      : {
          ...(state.userData[surveyNameSpace] as SurveyAnswersState),
          ...surveyAnswers,
        };
    const properties: JsonObject = {
      ...(dateKey && { [dateKey]: toISO() }),

      [surveyNameSpace]: finalSurveyAnswers,
      ...extraProps,

      language: getLanguage(),
      country: getCountryCode(),
      subdivision: getSubdivision(),
    };

    return submitUserDataUpdate(dispatch, getState, "save", properties).then(
      () => finalSurveyAnswers
    );
  };
}

/**
 * Thunk
 */
export function saveProfile(properties: { email: string; name?: string }) {
  return (dispatch: AppDispatch, getState: GetAppState) => {
    return submitUserDataUpdate(dispatch, getState, "saveProfile", properties);
  };
}

/**
 * Utility function used by the thunks to execute the api call. It requires
 * the dispatch function generated by the thunks to update the store and state
 * when the updated userData is received from the server.
 */
function submitUserDataUpdate(
  dispatch: AppDispatch,
  getState: GetAppState,
  saveType: string,
  properties: JsonObject
) {
  const state = getState();
  const { userData, serverContext } = state;
  const surveyAnswers = getSurveyAnswers(state);

  let payload = properties;
  payload.lastUpdated = Date.now();
  payload.clientUserId = serverContext.user_id;

  // Extract and pass in certain values needed for app/CS as part of userData
  const weightLossData = prepareDataForBackend(surveyAnswers);

  if (weightLossData) {
    payload = { ...weightLossData, ...payload };
  }

  updateSessionState("browser", { userDataExpected: true });

  logInfo(`saving => users/${userData.user_id}`, payload);
  return send("POST", `/userdata/api/v4/user/data/${saveType}/`, payload)
    .then((json) => {
      const newUserData: UserDataState = { ...json.data };
      if (json?.user_id) newUserData.user_id = json.user_id;
      dispatch(updateUserData(newUserData));

      // In the case where the backend user was freshly created, then we do not learn anything
      // new about the user. Therefore, only fire off tracking event if not new user.
      // The exception is when the user is on an email route. In that case, treat any new
      // user created events as new information.
      // Also update visitorStatus only on saveMainSurvey which is called after email capture.
      // This prevents multiple unnecessary visitorState tracking events.
      const isNewUser = json?.is_new_user || false;
      if (saveType === "saveMainSurvey" && (!isNewUser || isFromEmail())) {
        dispatch(
          updateVisitorStatusState({
            is_new_user: isNewUser,
          })
        );
      }

      setUserIdCookie(json?.user_id || newUserData.user_id);

      logInfo("Got user_id from server", json.user_id);
    })
    .catch((e: any) => {
      if (e?.response?.status === 403 || e?.response?.status === 401) {
        dispatch(handleHttpForbidden());
      } else {
        captureException(e, "UserDataSave-Failed to fetch");
      }
    });
}

function userDataReducer(
  state: UserDataState,
  action: UserDataAction
): UserDataState {
  switch (action.type) {
    case UPDATE_USER_DATA:
      return { ...state, ...action.userData };
    default:
      return state || {};
  }
}

export default userDataReducer;
