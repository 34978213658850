import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { matchPath } from "react-router";
import { Serializer } from "../persistence";
import { fetchProductPlanAsPlanById } from "src/utils/plans";
import getStore, { AppDispatch, GetAppState } from "../store";
import { Plan } from "./plans";
import { clearPromoCode } from "./promoCode";
import invariant from "tiny-invariant";
import {
  createForwardParams,
  getSearchQuery,
  removeURLParam,
} from "src/utils/urlParams";
import { isFromEmail, isHM, isInApp } from "src/utils/userSegment";
import { getLanguage, getRouteId } from "src/utils/meristemContext";
import { freeMonthsOfferConfiguration } from "src/utils/freeMonthsOffer";
import { setParam } from "src/pageDefinitions/goto/utils";
import { isFreeMonthsPlanOverrideEligible } from "src/utils/userSegment/features";
import { appConfig } from "src/config";
import {
  getSessionState,
  updateSessionState,
} from "src/pageDefinitions/session";
import { applyPromoCode } from "src/utils/api/purchase/checkout";
import { getRecommendedPlan, recommendPlan } from "@utils/calculateGoals";
import { getSurveyAnswers } from "src/hooks/survey/answers";

declare module "src/pageDefinitions/session" {
  interface BrowserSessionState {
    isForcedPlan?: boolean;
  }
}

const forcedPlanId = new URLSearchParams(window.location.search)
  .get("noom_plan_id")
  ?.trim?.();

// Maps to all of the pre-LBF payment funnel paths. This
// only needs to be maintained while we still support those paths.
const pathsWithPlans = [
  "/purchase/(en|es|de)/:planId",
  "/purchase/:planId",
  "/purchase-hm/:planId",
];

// Needed to help redirect from old paths to new paths, since there are emails out with these paths
function getPlanId() {
  const state = getStore().getState();
  const { recommendedPlan } = state;

  const pathMatch = /^\/purchase(?:-hm)?(?:\/(?:en|es|de))?\/(.*)/.exec(
    window.location.pathname
  );

  return recommendedPlan.noom_plan_id || pathMatch?.[1];
}

// This probably isn't the best place for this, but circular dependencies
// sort of dictated. This will be going away as soon as the checkout page
// is convert to LBF, so living with it for now.
export function legacyPurchaseRoot() {
  const state = getStore().getState();
  const { userData } = state;
  const routeId = getRouteId();

  const root = `/purchase${isHM(state) ? "-hm" : ""}`;

  const finalPlanId = getPlanId();

  /* istanbul ignore next sanity */
  if (!finalPlanId) {
    throw new Error("No plan id provided");
  }

  let purchaseUrl = isHM(state) ? `${root}/` : `${root}/${getLanguage()}/`;
  purchaseUrl += finalPlanId;

  const queryParams = new URLSearchParams();

  // Add route to purchase page url params since cookies don't work 100% of the time.
  setParam(queryParams, "route", routeId);

  const currentUrlParams = createForwardParams();
  currentUrlParams.forEach((value, key) => {
    queryParams.set(key, value);
  });

  if (isInApp()) {
    setParam(queryParams, "email", userData.email);
  }

  return `${purchaseUrl.replace(/\/$/, "")}/${getSearchQuery(queryParams)}`;
}

export function hrtPaymentRoot() {
  const routeId = getRouteId();
  const queryParams = new URLSearchParams();

  setParam(queryParams, "route", routeId);

  const currentUrlParams = createForwardParams();
  currentUrlParams.forEach((value, key) => {
    queryParams.set(key, value);
  });

  return `/ps/hrt-payment-checkout/${getSearchQuery(queryParams)}`;
}

function extractPlanFromPath(pathname: string) {
  for (let i = 0; i < pathsWithPlans.length; i++) {
    const match = matchPath<{ planId: string }>(pathname, {
      path: pathsWithPlans[i],
    });
    if (match?.params) {
      return match.params.planId;
    }
  }
  return undefined;
}

export type RecommendedPlanState = Partial<Plan> & {
  // The isForcedPlan flag will actually be persisted per session. So, that it will not force a plan upon future visits.
  isForcedPlan?: boolean;

  /**
   * Provides the most recent trial fee selected by the user.
   * Note:
   * - These selections can be indirect i.e. promo code is applied.
   * - There is a bug where someone will go through the buyflow then drop at the purchase page (after having picked a
   * trial price). Then they come back through email on the same device and they picked 0.5 trial price. But this
   * trial price is not available in email, so this results in a product catalog mismatch, and charging the customer
   * for a trial that should be free. That is why we set the userSelectedTrialFee to undefined if they're from email.
   */
  userSelectedTrialFee?: number;
};

export const serialize: Serializer<"recommendedPlan"> = {
  storage: "local",
  load(storedValue: RecommendedPlanState): RecommendedPlanState {
    // The serialize.load function runs before the store exists, so that's why we had to use isFromEmail(null)
    const userSelectedTrialFee = isFromEmail(null)
      ? undefined
      : storedValue?.userSelectedTrialFee;

    // Used only for free months offer to force the plan to a shorter one
    // It needs to take precedence over noom_plan_id which will be the longer plan in this context
    const shorterPlanID = freeMonthsOfferConfiguration()?.shorterPlanID;
    if (shorterPlanID && isFreeMonthsPlanOverrideEligible(null)) {
      return {
        noom_plan_id: shorterPlanID,
        isForcedPlan: true,
        userSelectedTrialFee,
      };
    }

    if (forcedPlanId) {
      // Note that we may want to remove the noom_plan_id query parameter in
      // the future, but for now it needs to remain to support rerolls into
      // older code.
      return {
        noom_plan_id: forcedPlanId,
        isForcedPlan: true,
        userSelectedTrialFee,
      };
    }

    // Seed plan allows the plan to be set via query parameter, without
    // making it be a forced plan. This is used for testing, and for email links to checkout.
    const seedPlanId = new URLSearchParams(window.location.search).get(
      "seed_plan_id"
    );
    if (seedPlanId) {
      removeURLParam("seed_plan_id");
      return {
        noom_plan_id: seedPlanId,
        isForcedPlan: false,
        userSelectedTrialFee,
      };
    }

    const pathPlan = extractPlanFromPath(window.location.pathname);
    if (pathPlan) {
      return {
        noom_plan_id: pathPlan,
        isForcedPlan: false,
        userSelectedTrialFee,
      };
    }

    return {
      ...storedValue,
      userSelectedTrialFee,
      ...(appConfig.USE_SESSION_STORAGE_FOR_PLAN_TEMPORARY_FLAG && {
        isForcedPlan: getSessionState("browser")?.isForcedPlan,
      }),
    };
  },
  save(plan: RecommendedPlanState) {
    // WARN: Remember backwards compatibility when changing this.
    const { noom_plan_id, isForcedPlan, userSelectedTrialFee } = plan;
    updateSessionState("browser", { isForcedPlan });
    return { noom_plan_id, isForcedPlan, userSelectedTrialFee };
  },
};

export function planHasCustomTrialPrice(plan: RecommendedPlanState) {
  return plan.userSelectedTrialFee != null;
}

function getTrialFee(userSelectedTrialFee, payloadTrialFee) {
  return userSelectedTrialFee ?? payloadTrialFee ?? 0;
}

const recommendedPlansSlice = createSlice({
  name: "recommendedPlan",
  initialState: {
    noom_plan_id: forcedPlanId,
    isForcedPlan: !!forcedPlanId,
  } as RecommendedPlanState,
  reducers: {
    setRecommendedPlan: (
      state,
      action: PayloadAction<RecommendedPlanState>
    ) => {
      return {
        ...action.payload,
        trial_fee: getTrialFee(
          state.userSelectedTrialFee,
          action.payload.trial_fee
        ),
        userSelectedTrialFee: state.userSelectedTrialFee,
        isForcedPlan: action.payload.noom_plan_id === forcedPlanId,
      };
    },
    setTrialFee: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        trial_fee: action.payload,
        userSelectedTrialFee: action.payload,
      };
    },
    updateRecommendedPlan: (
      state,
      action: PayloadAction<RecommendedPlanState>
    ) => {
      // Does have partial updates
      return {
        ...state,
        ...action.payload,
        trial_fee: getTrialFee(
          state.userSelectedTrialFee,
          action.payload.trial_fee
        ),
      };
    },
  },
});

export const { setTrialFee, updateRecommendedPlan } =
  recommendedPlansSlice.actions;

const { setRecommendedPlan } = recommendedPlansSlice.actions;

export default recommendedPlansSlice;

export function setRecommendedPlanById(
  noomPlanId: string,
  {
    overwriteForced = false,
    overwriteReset = false,
    keepPromoCode = false,
    reapplyPromoCode = false,
  } = {}
) {
  invariant(noomPlanId, "noomPlanId is required");
  return async (dispatch: AppDispatch, getState: GetAppState) => {
    const { recommendedPlan: currentPlan, promoCode } = getState();

    // Do not overwrite forced plan unless explicitly requested
    if (!overwriteForced && currentPlan?.isForcedPlan) {
      return;
    }

    // NOP if resetting to ourselves
    if (!overwriteReset && currentPlan?.noom_plan_id === noomPlanId) {
      return;
    }

    // If we are ejecting from a forced plan, pull the query param
    if (currentPlan?.isForcedPlan) {
      removeURLParam("noom_plan_id");
    }

    if (!keepPromoCode) {
      await dispatch(clearPromoCode());
    }

    const recommendedPlan = await fetchProductPlanAsPlanById(noomPlanId);

    if (!recommendedPlan) {
      throw new Error(`No plan found with noom_plan_id: ${noomPlanId}`);
    }

    if (reapplyPromoCode && promoCode.promoCodeApplied) {
      const discountInfo = await dispatch(
        applyPromoCode(recommendedPlan, promoCode.promoCodeApplied)
      );
      dispatch(
        setRecommendedPlan({
          ...recommendedPlan,
          ...discountInfo,
        })
      );
    } else {
      dispatch(setRecommendedPlan({ ...recommendedPlan }));
    }

    // If we are currently on the legacy purchase page, remap our URL
    // to avoid refresh and redirect issues.
    if (/\/purchase(-hm)?\//.test(window.location.pathname)) {
      const { history } = window;
      history.replaceState(
        history.state,
        "",
        legacyPurchaseRoot() + window.location.hash
      );
    }
  };
}

export async function updatePlanFromMedRedirect() {
  const { dispatch } = getStore();
  const surveyAnswers = getSurveyAnswers();
  const { recommendedPlanDuration } = recommendPlan(surveyAnswers);
  const { recommendedPlan } = await getRecommendedPlan({
    recommendedPlanDuration,
    isMedPlan: false,
  });

  dispatch(
    setRecommendedPlanById(recommendedPlan.noom_plan_id, {
      overwriteReset: true,
      overwriteForced: true,
    })
  );
}
