import { useSelector } from "react-redux";
import { isValidNumber } from "src/components/core/IntlTelInput/IntlTelInput";
import i18n from "src/locales";
import { isNursing } from "src/utils/pace/planTimeAdjustmentNursing";
import {
  CollapsedEnrollmentData,
  requestEnrollment,
} from "src/utils/api/enrollment";
import { trackEvent } from "src/utils/api/tracker";
import { getLanguage } from "src/utils/meristemContext";
import { prepareDataForBackend } from "src/utils/pace";
import { CoreReduxState } from "src/utils/redux/internal";
import { updateCheckoutState } from "src/utils/redux/slices/checkout";
import {
  EnrollmentErrors,
  PaymentEnrollmentFormState,
  updatePaymentEnrollmentFormState,
} from "src/utils/redux/slices/paymentEnrollmentForm";
import { errorConstants } from "src/utils/services/PurchaseErrorMessages";
import { genderConstantsMapping } from "src/utils/sexAndGender";
import {
  isAppFreemiumUpsell,
  isHM,
  isMobile,
  isUS,
} from "src/utils/userSegment";
import {
  isMultiUserPlanEligible,
  showAccountInfoAccordion,
  isInAppMedUpgrade,
  acceptedNoomClinicalOffer,
  hasExistingAcccount,
  isHRTSurvey,
  isAgeEligibleForMht,
  isAgeEligibleForMed,
} from "src/utils/userSegment/features";
import { isEmail, isName } from "src/utils/validate";
import { useCallbackRef, useOnce } from "./lifecycle";
import { useAppDispatch, useCheckout, useUserData } from "./redux";
import { useSurveyAnswers } from "./survey/answers";
import { useEffect } from "react";
import { calculateAge, format } from "@utils/datetime";
import { failNoomClinicalAgeValidation } from "src/utils/redux/slices/paymentEnrollmentFormActions";
import { getFullName } from "src/utils/name";

export function useEnrollmentForm() {
  const checkout = useCheckout();
  const enrollmentForm = useSelector(
    (state: CoreReduxState) => state.paymentEnrollmentForm
  );
  const {
    enrollmentInfo,
    enrollmentErrors = {},
    showInvalidAgeModal,
    termAgreementError,
  } = enrollmentForm;
  const hasEnrollmentErrors = Object.values(enrollmentErrors).some(Boolean);

  const dispatch = useAppDispatch();

  function updateEnrollmentInfo(
    newEnrollmentInfo: PaymentEnrollmentFormState["enrollmentInfo"],
    handleNameChangeMade = true
  ) {
    const nameChangeMade = handleNameChangeMade
      ? (newEnrollmentInfo.name || "") !== (enrollmentInfo.name || "")
      : enrollmentInfo.nameChangeMade;
    dispatch(
      updatePaymentEnrollmentFormState({
        enrollmentInfo: {
          ...newEnrollmentInfo,
          nameChangeMade: enrollmentInfo.nameChangeMade || nameChangeMade,
        },
        enrollmentErrors: validateEnrollmentInfo(newEnrollmentInfo),
      })
    );
  }

  // TODO: See if there is a better way to seed this
  // Set default values and default errors for enrollment form
  const userData = useUserData();
  useOnce(() => {
    if (!enrollmentInfo.name && !enrollmentInfo.email) {
      const newEnrollmentInfo = {
        ...enrollmentInfo,
        name: userData.name || userData.purchaseSurveyAnswers?.firstName,
        email: userData.email,
      };

      updateEnrollmentInfo(newEnrollmentInfo);
    }
  });

  useEffect(() => {
    dispatch(
      updatePaymentEnrollmentFormState({
        enrollmentErrors: validateEnrollmentInfo(enrollmentInfo),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function setField(
    values: Omit<
      Partial<PaymentEnrollmentFormState["enrollmentInfo"]>,
      "termAgreement"
    >
  ) {
    const newEnrollmentInfo = {
      ...enrollmentInfo,
      ...values,
    };

    if ("dateOfBirth" in values) {
      const oldDob = enrollmentInfo.dateOfBirth || "";
      const newDob = values.dateOfBirth || "";

      // If user deleted a character, automatically remove trailing slash /
      if (newDob.length < oldDob.length && newDob[newDob.length - 1] === "/") {
        newEnrollmentInfo.dateOfBirth = newDob.slice(0, newDob.length - 1);
      }
      // If user added a character
      else if (newDob.length > oldDob.length) {
        // Remove non numeric characters and ensure we only have 8 numbers max
        const rawDob = newDob.replace(/[^0-9]/g, "").slice(0, 8);
        const parts = [];
        if (rawDob.length) {
          // month
          parts.push(rawDob.slice(0, 2));
        }
        if (rawDob.length >= 2) {
          // day
          parts.push(rawDob.slice(2, 4));
        }
        if (rawDob.length >= 4) {
          // year
          parts.push(rawDob.slice(4));
        }
        // Automatically add a slash between month and day, day and year to ensure the format dd/mm/yyyy
        newEnrollmentInfo.dateOfBirth = parts.join("/");
      }
    }

    ["password", "confirmPassword", "email"].forEach((field) => {
      if (field in values) {
        newEnrollmentInfo[field] = values[field].replace(/\s/g, "");
      }
    });

    if ("firstName" in values || "lastName" in values) {
      // Keep the name value in sync even when hidden since
      // we still need to send it to the backend
      newEnrollmentInfo.name = getFullName(
        newEnrollmentInfo.firstName,
        newEnrollmentInfo.lastName
      );
    }

    updateEnrollmentInfo(newEnrollmentInfo);
    dispatch(updateCheckoutState({ userStartedEnteringPaymentInfo: true }));
  }

  function hasVisibleError(field?: keyof EnrollmentErrors) {
    const { showEnrollmentErrors } = checkout;

    const confirmPasswordRealTimeValidation =
      field === "confirmPassword" &&
      enrollmentInfo.password.length > 0 &&
      enrollmentInfo.password.length <= enrollmentInfo.confirmPassword.length;

    if (!showEnrollmentErrors && !confirmPasswordRealTimeValidation) {
      return false;
    }

    return field ? !!enrollmentErrors[field] : hasEnrollmentErrors;
  }

  function setTermAgreement(termAgreement: boolean) {
    dispatch(
      updatePaymentEnrollmentFormState({
        enrollmentInfo: {
          ...enrollmentInfo,
          termAgreement,
        },
        termAgreementError: null,
      })
    );
  }
  function validateTermAgreement() {
    if (isUS() && !enrollmentInfo.termAgreement) {
      const errorMessage = i18n.t("payment:terms:error");
      dispatch(
        updatePaymentEnrollmentFormState({
          termAgreementError: errorMessage,
        })
      );
      return {
        termAgreement: errorMessage,
      };
    }
    return undefined;
  }

  function validateAddressOfSecondaryUserInMultiUserPlan() {
    const { multiUserPlan } = checkout;
    const secondaryUserSameAddressConfirmation =
      isMultiUserPlanEligible() && multiUserPlan?.toggled
        ? !multiUserPlan.secondaryUserSameAddressConfirmation // validate that the checkbox is checked
        : false; // no need to validate

    dispatch(
      updatePaymentEnrollmentFormState({
        enrollmentErrors: {
          ...enrollmentErrors,
          secondaryUserSameAddressConfirmation,
        },
      })
    );
  }

  function validateForm() {
    if (
      (showAccountInfoAccordion() || isInAppMedUpgrade() || isHRTSurvey()) &&
      hasEnrollmentErrors
    ) {
      return enrollmentErrors;
    }
    return undefined;
  }

  function validateDateOfBirth() {
    if (hasEnrollmentField("dateOfBirth")) {
      const { dateOfBirth } = enrollmentInfo;
      const parts = dateOfBirth.split("/");
      const month = parseInt(parts[0], 10);
      const day = parseInt(parts[1], 10);
      const year = parseInt(parts[2], 10);

      const dob = new Date(year, month - 1, day);
      const age = calculateAge(dob);
      const failHRTValidation = isHRTSurvey() && !isAgeEligibleForMht(age);
      const failMedValidation = !isHRTSurvey() && !isAgeEligibleForMed(age);

      if (failHRTValidation || failMedValidation) {
        dispatch(failNoomClinicalAgeValidation());
        return true;
      }
    }
    return false;
  }

  function applyError(error: errorConstants) {
    if (error === errorConstants.errorTermAgreement) {
      dispatch(
        updatePaymentEnrollmentFormState({
          termAgreementError: i18n.t("payment:terms:error"),
        })
      );
      return true;
    }

    const errorMap = {
      [errorConstants.errorActiveSubscription]: {
        email: i18n.t(
          "enrollmentForm:inputFields:email:errorMessages:activeSubscription"
        ),
      },
      [errorConstants.errorPendingDeletionRequest]: {
        email: i18n.t(
          "enrollmentForm:inputFields:email:errorMessages:pendingDeletionRequest"
        ),
      },
      [errorConstants.errorInvalidEmail]: {
        email: i18n.t("enrollmentForm:inputFields:email:errorMessages:invalid"),
      },
    };

    if (error === errorConstants.noomClinicalIneligibleAge) {
      dispatch(failNoomClinicalAgeValidation());
      return true;
    }

    const errorState = errorMap[error];
    if (errorState) {
      dispatch(
        updatePaymentEnrollmentFormState({
          enrollmentErrors: {
            ...enrollmentErrors,
            ...errorState,
          },
        })
      );
      return true;
    }

    return false;
  }

  return {
    enrollmentInfo,
    setField: useCallbackRef(setField),
    updateEnrollmentInfo: useCallbackRef(updateEnrollmentInfo),

    validateForm: useCallbackRef(validateForm),

    enrollmentErrors,
    hasEnrollmentErrors,
    hasVisibleError: useCallbackRef(hasVisibleError),
    applyError: useCallbackRef(applyError),

    termAgreementError,
    setTermAgreement: useCallbackRef(setTermAgreement),
    validateTermAgreement: useCallbackRef(validateTermAgreement),
    validateAddressOfSecondaryUserInMultiUserPlan: useCallbackRef(
      validateAddressOfSecondaryUserInMultiUserPlan
    ),
    showInvalidAgeModal,
    validateDateOfBirth,
  };
}

export function useEnroll() {
  const { enrollmentInfo } = useEnrollmentForm();
  const surveyAnswers = useSurveyAnswers();

  async function enroll(curriculum?: string) {
    // Track survey answer fields that do not exist and populate with default values
    const missingFields = [];
    let { gender, heightCm, weightKg, ageRange, liabilityWaiver } =
      surveyAnswers;
    let genderMapping: string;
    if (!gender) {
      missingFields.push("gender");
      genderMapping = genderConstantsMapping.UNKNOWN;
    } else {
      genderMapping = genderConstantsMapping[gender[0]];
    }
    if (!heightCm) {
      missingFields.push("height");
      heightCm = 170;
    }
    if (!weightKg) {
      missingFields.push("weight");
      weightKg = 65;
    }
    if (!ageRange) {
      missingFields.push("age");
      ageRange = ["40s"];
    }
    // Fire off event only if missingFields is empty
    if (missingFields.length && !isHM()) {
      trackEvent("ProfileFieldMissing", {
        missingFields,
      });
    }
    const { email, name, password, phoneNumber } = enrollmentInfo;

    // Pass in data needed for CS
    const userProfileData: CollapsedEnrollmentData = {
      email,
      name,
      password,
      phoneNumber,
      language: getLanguage(),
      gender: genderMapping,
      heightInCm: heightCm,
      weightInKg: weightKg,
      age: parseInt(ageRange[0], 10),
      isNursing: isNursing(surveyAnswers),
      ...(liabilityWaiver &&
        liabilityWaiver[0] === "agree" && {
          liabilityWaiverConsent: liabilityWaiver[0].toUpperCase(),
        }),
    };

    if (!isHM()) {
      const weightLossData = prepareDataForBackend(surveyAnswers);
      if (weightLossData) {
        (userProfileData as any).weightLossData = weightLossData;
      }
    }

    return requestEnrollment({
      userProfileData,
      // Filter HW curriculum to maintain parity with prior impl
      // TODO: Check if the API is fine with this being passed
      curriculum: curriculum === "HW" ? undefined : curriculum,
    });
  }

  return {
    enroll: useCallbackRef(enroll),
  };
}

function getDateOfBirthError(dateOfBirth: string) {
  const dob = new Date(dateOfBirth);

  if (!dateOfBirth) {
    return i18n.t(
      "enrollmentForm:inputFields:dateOfBirth:errorMessages:invalid"
    );
  }

  // by checking the inputted {String} dob against the formatted version of {Date} dob
  // we can verify that the input was correctly formatted and the date exists
  if (dateOfBirth !== format("{MM}/{DD}/{YYYY}", dob)) {
    return i18n.t(
      "enrollmentForm:inputFields:dateOfBirth:errorMessages:invalidFormat"
    );
  }

  const today = new Date();
  if (dob > today) {
    return i18n.t(
      "enrollmentForm:inputFields:dateOfBirth:errorMessages:future"
    );
  }

  return "";
}

function getInvalidNameCharacters(name: string) {
  const allowedPattern = /[a-zA-ZÀ-ÖØ-öø-ÿ0-9\s\-'']/g;

  const invalidChars = [...new Set(name.replace(allowedPattern, ""))];

  return invalidChars.join("");
}

function getFirstOrLastNameFieldErrors(
  key: "firstName" | "lastName",
  fieldValue: string
) {
  if (!isName(fieldValue)) {
    return i18n.t<string>(
      `enrollmentForm:inputFields:${key}:errorMessages:required`
    );
  }

  if (fieldValue.length < 2) {
    return i18n.t<string>(
      "enrollmentForm:inputFields:firstOrLastName:errorMessages:tooShort"
    );
  }

  if (getInvalidNameCharacters(fieldValue)) {
    return i18n.t<string>(
      "enrollmentForm:inputFields:firstOrLastName:errorMessages:invalidCharacters",
      {
        invalidCharacters: getInvalidNameCharacters(fieldValue),
      }
    );
  }

  return "";
}

function validateEnrollmentInfo(
  enrollmentInfo: PaymentEnrollmentFormState["enrollmentInfo"]
) {
  const {
    email,
    password,
    confirmPassword,
    name,
    firstName,
    lastName,
    phoneNumber,
    phoneNumberCountryCode,
    dateOfBirth,
  } = enrollmentInfo;
  const enrollmentErrors: EnrollmentErrors = {
    name: false,
    email: false,
    password: false,
    confirmPassword: false,
    phoneNumber: false,
  };

  if (hasEnrollmentField("name")) {
    if (!isName(name)) {
      enrollmentErrors.name = i18n.t<string>(
        "enrollmentForm:inputFields:name:errorMessages:required"
      );
    }
  }

  if (hasEnrollmentField("firstName")) {
    enrollmentErrors.firstName = getFirstOrLastNameFieldErrors(
      "firstName",
      firstName
    );
  }
  if (hasEnrollmentField("lastName")) {
    enrollmentErrors.lastName = getFirstOrLastNameFieldErrors(
      "lastName",
      lastName
    );
  }
  if (hasEnrollmentField("dateOfBirth")) {
    enrollmentErrors.dateOfBirth = getDateOfBirthError(dateOfBirth);
  }

  if (hasEnrollmentField("email") && !isEmail(email)) {
    enrollmentErrors.email = i18n.t<string>(
      "enrollmentForm:inputFields:email:errorMessages:invalid"
    );
  }

  if (hasEnrollmentField("password")) {
    if (password?.length < 1) {
      enrollmentErrors.password = i18n.t<string>(
        "enrollmentForm:inputFields:password:errorMessages:required"
      );
    } else if (password?.length < 4) {
      enrollmentErrors.password = i18n.t<string>(
        "enrollmentForm:inputFields:password:errorMessages:minLength"
      );
    }
    if (hasEnrollmentField("confirmPassword") && password !== confirmPassword) {
      enrollmentErrors.confirmPassword = i18n.t<string>(
        "enrollmentForm:inputFields:confirmPassword:errorMessages:passwordsNotMatching"
      );
    }
  }
  if (
    hasEnrollmentField("phoneNumber") &&
    !isValidNumber(phoneNumber, phoneNumberCountryCode)
  ) {
    enrollmentErrors.phoneNumber = i18n.t<string>(
      "enrollmentForm:inputFields:phoneNumber:errorMessages:invalid"
    );
  }
  return enrollmentErrors;
}

export type InputFieldKey =
  | "firstName"
  | "lastName"
  | "name"
  | "email"
  | "dateOfBirth"
  | "password"
  | "confirmPassword"
  | "smsConsent"
  | "phoneNumber"
  | "phoneDisclaimer";

export function getEnrollmentFormFields(): Array<Array<InputFieldKey>> {
  if (isHRTSurvey()) {
    return [
      ["firstName", "lastName"],
      ["dateOfBirth", "phoneNumber"],
      ["smsConsent"],
    ];
  }

  if (isInAppMedUpgrade()) {
    return [["firstName", "lastName"], ["phoneNumber"], ["smsConsent"]];
  }

  if (acceptedNoomClinicalOffer()) {
    return [
      ["firstName", "lastName"],
      ["email"],
      !hasExistingAcccount() && ["password", "confirmPassword"],
      ["dateOfBirth", "phoneNumber"],
      ["smsConsent"],
    ].filter(Boolean) as Array<Array<InputFieldKey>>;
  }

  return [
    ["name"],
    !isAppFreemiumUpsell() && ["email"],
    !hasExistingAcccount() && ["password", "confirmPassword"],
    !isMobile() && ["phoneNumber", "phoneDisclaimer"],
  ].filter(Boolean) as Array<Array<InputFieldKey>>;
}

function hasEnrollmentField(fieldKey: InputFieldKey) {
  return getEnrollmentFormFields().flat().includes(fieldKey);
}
