import { addSurveyAnswers } from "@utils/redux/slices/surveyAnswers";
import {
  isEligibleForFreemium,
  isFreemiumPageset,
  shouldHideExistingPurchaseSurveyAnswers,
} from "@utils/userSegment/features";
import { resolveValue } from "src/pageDefinitions/values";
import { trackBuyflowEvent, trackEvent } from "src/utils/api/tracker";
import { logDebug } from "src/utils/monitoring/logging";
import { ActionHandler, ActionHandlerParams } from "..";
import { getPageSetSurvey } from "./util";
import { seedCurrentAnswers } from "src/utils/redux/slices/currentAnswers";
import isEmpty from "lodash/isEmpty";
import { getRouteId } from "src/utils/meristemContext";
import saveToBraze from "src/utils/brazeUploader";
import { getEventPropertiesForNoomFree } from "src/utils/monitoring/events";

interface EventProperties {
  surveyName: string;
  isVibeInstalled?: boolean;
  campaignName?: string;
  campaignId?: string;
}

export const onStartSurvey: ActionHandler = ({
  pageSet,
  page,
  store,
}: ActionHandlerParams) => {
  const survey = getPageSetSurvey(pageSet, page);

  const isFreemiumUser = isEligibleForFreemium();

  const eventProperties: EventProperties = {
    surveyName: survey.name,
    ...(isFreemiumUser ? getEventPropertiesForNoomFree() : {}),
  };

  trackBuyflowEvent("BuyflowSurveyStarted", eventProperties);

  if (isFreemiumUser && isFreemiumPageset(pageSet)) {
    saveToBraze({
      event: {
        event_name: "buyflowSurveyStarted",
        event_data: {
          routeId: getRouteId(),
        },
      },
    });
  }

  const { surveyNameSpace } = survey;
  const state = store.getState();
  const { currentAnswers, surveyAnswers } = state;

  logDebug(`Loading survey answers from ${surveyNameSpace}`);
  const lookupValue = resolveValue(surveyNameSpace, state) || {};

  // check if there are answers stored in surveyAnswers (local storage) that
  // could potentially be auto-applied if transferred to currentAnswers. this is
  // to gauge scope for a potential experiment.
  if (surveyNameSpace === "mainSurvey") {
    const currentAnswersObj =
      currentAnswers?.questionAnswers?.[surveyNameSpace] || lookupValue;

    if (isEmpty(currentAnswersObj) && !isEmpty(surveyAnswers)) {
      trackEvent("AnswersAvailableToAutoApply");
    }
  }
  const hideExistingAnswers =
    surveyNameSpace === "userData.weeklyCheckInSurvey" ||
    (surveyNameSpace === "userData.purchaseSurveyAnswers" &&
      shouldHideExistingPurchaseSurveyAnswers());

  if (!hideExistingAnswers) {
    store.dispatch(addSurveyAnswers(lookupValue));
    // Merge answers from user data with those stored locally, giving precedence to the
    // local responses.
    store.dispatch(
      seedCurrentAnswers({
        namespace: surveyNameSpace,
        answers: lookupValue,
      })
    );
  }
};
