import React from "react";
import { isHM, isMedRoute } from "src/utils/userSegment";
import { compassColors } from "@utils/styling";
import { isSubscriptionMedTier } from "@utils/userSegment/features";

function NoomText() {
  return (
    <svg
      css={{ height: "100%" }}
      viewBox="0 0 594.734 133.4"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Noom</title>
      <path
        d="M594.3 131.6h-22.1V25.4l-33.4 57.8c-3.8 6.7-10.9 10.9-18.6 10.8-7.7 0-14.9-4.1-18.7-10.8l-33.4-58.3v106.7H446V23.8c0-11.9 9.7-21.5 21.6-21.5 7.7 0 14.8 4.1 18.6 10.8l34 59.8 34-59.8c5.9-10.3 19.1-13.9 29.4-8 6.7 3.8 10.8 10.9 10.8 18.7l-.1 107.8Zm-498.9 0c-7.7 0-14.8-4.2-18.5-10.9L22.1 24.9v106.7H0V23.8C0 11.9 9.6 2.3 21.4 2.2c7.8 0 14.9 4.1 18.8 10.9L95 108.9V2.2h22.1v107.9c.1 9.8-6.5 18.4-16 20.8-1.9.5-3.8.7-5.7.7Zm110.7 1.8c-36.8 0-66.6-29.8-66.6-66.6S169.3.2 206.1.2s66.6 29.8 66.6 66.6c.1 36.7-29.5 66.5-66.2 66.6h-.4Zm0-111.2c-24.4 0-43.6 19.6-43.6 44.6-.2 11.9 4.4 23.4 12.7 31.9 17 17 44.6 17 61.6 0 8.3-8.5 12.8-20 12.7-31.9.1-25-19-44.6-43.4-44.6Zm151 111.2c-36.8 0-66.6-29.8-66.6-66.6S320.3.2 357.1.2s66.6 29.8 66.6 66.6c.1 36.7-29.5 66.5-66.2 66.6h-.4Zm0-111.2c-24.4 0-43.6 19.6-43.6 44.6-.1 11.9 4.4 23.4 12.7 31.9 17 17 44.6 17 61.6 0 8.3-8.5 12.8-20 12.7-31.9 0-25-19.1-44.6-43.4-44.6Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function WeightPill({ marginLeft = 7 }) {
  return (
    <svg
      // Height needs to be set or else the pill logo will not show on safari
      css={{ marginLeft, height: "100%" }}
      viewBox="633.124 0 446.876 133.4"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Weight Logo</title>
      <path
        d="M1015.2 2.2H698c-35.8 0-64.8 29-64.8 64.7 0 35.8 29 64.8 64.7 64.8h317.3c35.8 0 64.8-29 64.8-64.8 0-35.7-29-64.7-64.8-64.7ZM749.4 93.7h-6.2l-12.1-34.8-11.8 34.8h-6.2l-16.3-50.9h8.8l11 34.3 11.7-34.3h6.2L746.3 77l11-34.2h8.6l-16.5 50.9Zm61.3 0h-30.5V42.8h29.7v8h-21v11.6h16.3v8h-16.3v15.3h21.8v8Zm25.5 0h-8.7V42.9h8.7v50.8Zm63.6-9.1c-4.6 5.8-12 9.8-20.2 9.8-15.8 0-26.6-11.2-26.6-26.1 0-15 11.3-26.1 26.1-26.1 7.7 0 14.4 3.3 18.9 8.2l-5.4 5.4c-3.4-3.3-7.8-5.7-13.5-5.7-10.1 0-17.3 8-17.3 18.1 0 10.1 7.3 18.1 17.9 18.1 4.9 0 8.6-1.5 11.8-4.1V70.3h-14.1v-7.6h22.4v21.9Zm60.9 9.1H952V70.5h-26.1v23.2h-8.7V42.8h8.7v19.6H952V42.8h8.7v50.9Zm51.6-42.9h-13.8v42.9h-8.7V50.8H976v-8h36.3v8Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function InAppMedPill() {
  return (
    <svg
      // Height needs to be set or else the pill logo will not show on safari
      css={{ marginLeft: "7px", height: "100%" }}
      viewBox="0 0 55 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Med Logo</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.44361 0C3.33267 0 0 3.58172 0 8C0 12.4183 3.33267 16 7.44361 16H47.5564C51.6673 16 55 12.4183 55 8C55 3.58172 51.6673 0 47.5564 0H7.44361Z"
        fill={compassColors.blueberry}
      />
      <path
        d="M33.7461 13V3.19995H37.1761C38.8141 3.19995 40.0181 3.70395 40.8861 4.54395C41.7961 5.42595 42.3561 6.68595 42.3561 8.09995C42.3561 9.51395 41.7961 10.774 40.8861 11.656C40.0181 12.496 38.8141 13 37.1761 13H33.7461ZM35.4261 11.46H37.0361C38.3241 11.46 39.1921 11.082 39.7941 10.438C40.3541 9.84995 40.6761 9.03795 40.6761 8.09995C40.6761 7.16195 40.3541 6.34995 39.7941 5.76195C39.1921 5.11795 38.3241 4.73995 37.0361 4.73995H35.4261V11.46Z"
        fill={compassColors.sky}
      />
      <path
        d="M25.5859 13V3.19995H31.3259V4.73995H27.2659V6.97995H30.4159V8.51995H27.2659V11.46H31.4659V13H25.5859Z"
        fill={compassColors.sky}
      />
      <path
        d="M13.2188 13V3.19995H14.4087L18.0068 8.44995L21.5487 3.19995H22.7387V13H21.1008V6.64395L18.1467 10.984H17.8108L14.8568 6.65795V13H13.2188Z"
        fill={compassColors.sky}
      />
    </svg>
  );
}

function MedPill({ isInvertedColors = false }: { isInvertedColors?: boolean }) {
  return (
    <svg
      // Height needs to be set or else the pill logo will not show on safari
      css={{ marginLeft: "7px", height: "100%" }}
      viewBox="0 0 55 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Med Logo</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.44361 0C3.33267 0 0 3.58172 0 8C0 12.4183 3.33267 16 7.44361 16H47.5564C51.6673 16 55 12.4183 55 8C55 3.58172 51.6673 0 47.5564 0H7.44361Z"
        fill={isInvertedColors ? compassColors.white : compassColors.black}
      />
      <path
        d="M33.7461 13V3.19995H37.1761C38.8141 3.19995 40.0181 3.70395 40.8861 4.54395C41.7961 5.42595 42.3561 6.68595 42.3561 8.09995C42.3561 9.51395 41.7961 10.774 40.8861 11.656C40.0181 12.496 38.8141 13 37.1761 13H33.7461ZM35.4261 11.46H37.0361C38.3241 11.46 39.1921 11.082 39.7941 10.438C40.3541 9.84995 40.6761 9.03795 40.6761 8.09995C40.6761 7.16195 40.3541 6.34995 39.7941 5.76195C39.1921 5.11795 38.3241 4.73995 37.0361 4.73995H35.4261V11.46Z"
        fill={isInvertedColors ? compassColors.blueberry : compassColors.white}
      />
      <path
        d="M25.5859 13V3.19995H31.3259V4.73995H27.2659V6.97995H30.4159V8.51995H27.2659V11.46H31.4659V13H25.5859Z"
        fill={isInvertedColors ? compassColors.blueberry : compassColors.white}
      />
      <path
        d="M13.2188 13V3.19995H14.4087L18.0068 8.44995L21.5487 3.19995H22.7387V13H21.1008V6.64395L18.1467 10.984H17.8108L14.8568 6.65795V13H13.2188Z"
        fill={isInvertedColors ? compassColors.blueberry : compassColors.white}
      />
    </svg>
  );
}

export function PremiumPill() {
  return (
    <svg
      css={{ height: "100%", marginLeft: 4 }}
      viewBox="88 0 69 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="88"
        width="69"
        height="19"
        rx="9.5"
        fill="url(#paint0_linear_1424_4115)"
      />
      <path
        d="M96.485 9.164V6.896h1.62c.528 0 .78.096.972.264.252.204.408.516.408.876s-.156.66-.408.864c-.192.168-.444.264-.972.264h-1.62zm0 1.296h1.764c.792 0 1.296-.18 1.704-.48a2.403 2.403 0 00.972-1.944c0-.816-.384-1.512-.972-1.956-.408-.3-.912-.48-1.704-.48h-3.204V14h1.44v-3.54zm7.601-.24V14h-1.44V5.6h3.024c.924 0 1.524.276 1.944.72.384.408.612.972.612 1.596 0 .624-.228 1.176-.612 1.584-.276.3-.636.516-1.116.624L109.426 14h-1.728l-2.832-3.78h-.78zm0-3.324V8.96h1.428c.528 0 .792-.108.972-.288.192-.18.3-.456.3-.732 0-.3-.108-.576-.3-.756-.18-.18-.444-.288-.972-.288h-1.428zm6.621-1.296V14h5.04v-1.32h-3.6v-2.52h2.7V8.84h-2.7V6.92h3.48V5.6h-4.92zm6.992 8.4V5.6h1.02l3.084 4.5 3.036-4.5h1.02V14h-1.404V8.552l-2.532 3.72h-.288l-2.532-3.708V14h-1.404zm10.602 0h1.44V5.6h-1.44V14zm9.431-3.42V5.6h1.44v4.896c0 1.116-.312 1.872-.828 2.46-.648.732-1.632 1.164-2.712 1.164-1.08 0-2.064-.444-2.712-1.176-.528-.576-.828-1.332-.828-2.448V5.6h1.44v4.98c0 .756.18 1.2.492 1.536.384.42.96.684 1.608.684.648 0 1.224-.264 1.608-.684.312-.336.492-.78.492-1.536zm3.793 3.42V5.6h1.02l3.084 4.5 3.036-4.5h1.02V14h-1.404V8.552l-2.532 3.72h-.288l-2.532-3.708V14h-1.404z"
        fill="#ffffff"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1424_4115"
          x1="95.884"
          y1="0"
          x2="95.884"
          y2="10.799"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AD925E" />
          <stop offset="1" stopColor="#72513A" />
        </linearGradient>
      </defs>
    </svg>
  );
}

function MoodPill() {
  return (
    <svg
      // Height needs to be set or else the pill logo will not show on safari
      css={{ marginLeft: 7, height: "100%" }}
      viewBox="633.124 0 366.721 129.7"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Mood Logo</title>
      <path
        d="
          M935.045-.212 698 .2c-35.8 0-64.8 29-64.8 64.7 0 35.8 29 64.8 64.7 64.8l237.145-.412c35.8 0 64.8-29 64.8-64.8 0-35.7-29-64.7-64.8-64.7Z
          M800.862 43.252c18.344 9.981 17.402 37.564-1.579 46.404-17.392 8.097-36.615-4.449-36.645-23.914-.035-20.009 20.542-32.114 38.224-22.49Zm63.943 0c18.342 9.981 17.401 37.564-1.582 46.404-17.39 8.097-36.612-4.449-36.644-23.914-.032-20.009 20.543-32.114 38.226-22.49m-152.639 11.2c5.21 7.656 9.76 13.913 10.115 13.903.351-.012 4.766-6.276 9.81-13.924 8.222-12.467 9.474-13.901 12.114-13.901h2.949v51.566h-8.199l-.284-17.274-.28-17.276-8.132 11.756-8.131 11.757-1.916-2.475c-1.054-1.361-4.588-6.528-7.853-11.481-7.685-11.653-7.487-11.864-7.487 7.978v17.015h-8.252V40.53h3.036c2.745 0 3.953 1.346 12.51 13.922m209.527-12.307c17.5 5.961 22.225 30.564 8.233 42.848-5.988 5.257-9.832 6.376-23.686 6.889l-12.636.464V40.53h11.678c7.886 0 13.214.525 16.411 1.615m-141.122 6.54c-17.612 7.997-11.506 36.193 7.838 36.193 10.822 0 16.8-6.172 17.297-17.842.657-15.519-11.625-24.488-25.135-18.351m63.945 0c-17.615 7.997-11.508 36.193 7.834 36.193 10.823 0 16.802-6.172 17.298-17.842.657-15.519-11.623-24.488-25.132-18.351m57.338 17.775v18.712l8.095-.556c28.781-1.971 28.135-36.864-.683-36.864h-7.412V66.46Z"
        fillRule="evenodd"
        fill="currentColor"
      />
    </svg>
  );
}

const isTelehealthRoute = () => {
  return window.location.href.includes("telehealth");
};

export function NoomLogo({
  showPill,
  className,
  onSecretClick,
  pillElement,
  isInvertedColors,
}: {
  /**
   * Omit for for default behavior for vertical.
   * true to always include.
   * false to never include
   */
  showPill?: boolean;
  className?: string;
  pillElement?: JSX.Element;
  isInvertedColors?: boolean;

  /**
   * Allows for intentionally non-accessible click handling
   * to support features like the secret quick silver launcher.
   * Use of this is discouraged for any user facing features.
   */
  onSecretClick?: () => void;
}) {
  let pillEl: JSX.Element;
  if ((showPill == null && isHM()) || showPill) {
    if (pillElement) {
      pillEl = pillElement;
    } else if (isHM()) {
      pillEl = <MoodPill />;
    } else if (isTelehealthRoute()) {
      // Telehealth is removing the logo pill before it has been removed
      // from the whole app. So, special casing things in this case but
      // can just remove this whole branch once it is done app-wide.
      pillEl = null;
    } else if (isMedRoute() || isSubscriptionMedTier()) {
      pillEl = <MedPill isInvertedColors={isInvertedColors} />;
    } else {
      pillEl = <WeightPill />;
    }
  }
  return (
    /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */
    <span
      onClick={onSecretClick}
      css={{ display: "flex", height: "100%" }}
      className={className}
    >
      <NoomText />
      {pillEl}
    </span>
  );
}
