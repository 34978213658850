import { routeConstants } from "../constants";
import { checkSegments } from "./util";
import { isUS } from "./locale";
import { getInAppAutoCookie } from "@utils/authCookieParser";
import semVerGte from "semver/functions/gte";
import semVerCoerce from "semver/functions/coerce";

export const isAppEmail = checkSegments({
  oneOf: [routeConstants.appEmail],
});

export const isAppIosBase = checkSegments({
  oneOf: [routeConstants.app14DayMain],
});

export const isAppAndroidBase = checkSegments({
  oneOf: [routeConstants.app14DayWebMain],
});

export const isAppIosWinback = checkSegments({
  oneOf: [routeConstants.app14DayReturning],
});

export const isAppAndroidWinback = checkSegments({
  oneOf: [routeConstants.app14DayFtWeb],
});

export function isIOSPlatform() {
  const { platform } = getInAppAutoCookie() || {};
  return platform?.toUpperCase() === "IOS";
}

export function isAndroidPlatform() {
  const { platform } = getInAppAutoCookie() || {};
  return platform?.toUpperCase() === "ANDROID";
}

export const isAppIOSFreemiumUpsell = checkSegments({
  oneOf: [routeConstants.freemiumClinical, routeConstants.freemiumWeight],
  allOf: [isIOSPlatform],
});

export const isAppIosFreemiumRoute = checkSegments({
  allOf: [isIOSPlatform, routeConstants.appFreemiumIU],
});

export const isAppIosBaseAndWinback = checkSegments({
  oneOf: [isAppIosBase, isAppIosWinback],
});

export const isAppIos = checkSegments({
  oneOf: [
    isAppIosBase,
    isAppIosWinback,
    isAppIOSFreemiumUpsell,
    isAppIosFreemiumRoute,
  ],
});

export const isAppAndroidFreemiumUpsell = checkSegments({
  allOf: [isAndroidPlatform],
  oneOf: [routeConstants.freemiumClinical, routeConstants.freemiumWeight],
});

export const isAppAndroid = checkSegments({
  oneOf: [isAppAndroidBase, isAppAndroidWinback, isAppAndroidFreemiumUpsell],
});

export const isAppBaseRoute = checkSegments({
  oneOf: [isAppIosBase, isAppAndroidBase],
});

export const isInAppWinback = checkSegments({
  oneOf: [isAppAndroidWinback, isAppIosWinback],
});

export const isAppFreemiumUpsell = checkSegments({
  oneOf: [isAppAndroidFreemiumUpsell, isAppIOSFreemiumUpsell],
});

export const isInApp = checkSegments({
  oneOf: [
    isInAppWinback,
    isAppBaseRoute,
    routeConstants.appUpsell,
    routeConstants.appFreemiumIU,
    isAppFreemiumUpsell,
  ],
});

export const isUSInAppWinback = checkSegments({
  allOf: [isUS],
  oneOf: [isInAppWinback],
});

export const isFreemiumUnidentifiedRoute = checkSegments({
  oneOf: [isAppAndroidBase, isAppIosBase, isAppEmail],
});
export const isAppVersionGTE = (version: `${number}.${number}`) => {
  /**
   * Checks if the current app version is greater than or equal to the provided version.
   *
   * @param version - The version to check against in a basic semver format.
   * NOTE: the type allows 1.2.3 because 2.3 is a floating number
   *
   * @returns {boolean} - Returns false if appVersion is not in the cookie.
   * Returns true if the current app version is greater than or equal to the provided version, false otherwise.
   */
  const appVersion = semVerCoerce(getInAppAutoCookie()?.appVersion);
  return appVersion && semVerGte(appVersion, version);
};
