import { expectUserData } from "src/utils/redux/slices/userData";
import getStore from "src/utils/redux/store";
import { registerMonitorProvider } from "../prop-registry";

/**
 * Collect SEO properties(search engine extracted from document.referrer)
 * We also should return empty dict if referrer contains certain words
 */
registerMonitorProvider("both", () => {
  const { upid, recommendedPlan, linearBuyflow, serverContext } =
    getStore().getState();
  return {
    noomPlanId: recommendedPlan.noom_plan_id,
    isForcedPlan: recommendedPlan.isForcedPlan,
    initialPlanLength: recommendedPlan.billing_cycle_duration,
    userSelectedTrialFee: recommendedPlan.userSelectedTrialFee,
    pageSet: linearBuyflow.pageSetName,
    currentPlanBraintreeId: serverContext.current_plan_id,
    upid,
    userDataExpected: expectUserData(),
  };
});

/**
 * Collect properties about the user's visitor status using visitorStatus slice
 */
registerMonitorProvider("monitoring", () => {
  const { visitorStatus } = getStore().getState();
  // Format properties with the proper prefix
  const properties: JsonObject = {};
  Object.keys(visitorStatus).forEach((item) => {
    properties[`visitorStatus_${item}`] = visitorStatus[item];
  });
  return properties;
});
