import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isPlanSelectionEligible } from "src/utils/userSegment/features";
import { Serializer } from "@utils/redux/persistence";
import { getSessionState, updateSessionState } from "@pageDefinitions/session";
import { CoreReduxState } from "../internal";
import getStore from "../store";
import { freeMonthsOfferConfiguration } from "src/utils/freeMonthsOffer";
import { isHolidayPromoCode } from "src/utils/holidayPromo";
import { isCoachReferralsRoute } from "src/utils/userSegment";

declare module "src/pageDefinitions/session" {
  interface BrowserSessionState {
    promoCodePersisted?: boolean;
    promoCodePersistedApplied?: string;
  }
}

export type PromoCodeErrorType =
  | "IncorrectPromoCode"
  | "PromoCodeRedemptionBlocked"
  | "UnknownError";

const promoCodeInitialState = {
  promoCodeApplied: "",
  promoDiscountAmount: 0,
  promoDiscountPercentage: 0,
  promoCodeIsVip: false,
  promoCodeError: "",
  promoCodeErrorType: null,
  promoCodeExpirationDate: "",
  promoCodeFreeMonths: null,
  promoCodeInitialCode: null,
} as PromoCodeState;

export type PromoCodeState = {
  promoCodeApplied: string;
  promoDiscountAmount: number;
  promoDiscountPercentage: number;
  promoCodeIsVip: boolean;
  promoCodeError: string;
  promoCodeErrorType: PromoCodeErrorType;
  promoCodeExpirationDate: string;
  promoCodeFreeMonths?: number | null;
  promoCodeInitialCode?: string | null;
};

export const serialize: Serializer<"promoCode"> = {
  storage: "local",
  load: (storedValue: PromoCodeState) => {
    if (storedValue) {
      updateSessionState("browser", {
        promoCodePersisted: storedValue.promoCodeApplied?.length > 0,
        promoCodePersistedApplied: storedValue.promoCodeApplied,
      });
    }
    return storedValue;
  },
  save: (promoCode: PromoCodeState) => {
    // don't persist promocode state for free month offers as it could
    // cause buggy behaviour if user comes back on different routes
    if (
      freeMonthsOfferConfiguration() ||
      isHolidayPromoCode(promoCode.promoCodeInitialCode) ||
      isCoachReferralsRoute()
    ) {
      return promoCodeInitialState;
    }

    return {
      ...promoCode,
      promoCodeError: "",
    };
  },
};

const promoCode = createSlice({
  name: "promoCode",
  initialState: promoCodeInitialState,
  reducers: {
    setPromoCode(state, action: PayloadAction<Partial<PromoCodeState>>) {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearPromoCode() {
      return {
        ...promoCodeInitialState,
      };
    },
  },
});

export function getPromoCodeState(
  state: CoreReduxState = getStore().getState()
) {
  const { recommendedPlan, promoCode: promoCodeSlice, planOptions } = state;
  if (
    recommendedPlan?.billing_cycle_duration !==
      planOptions.preSelected?.billing_cycle_duration &&
    !promoCodeSlice.promoCodeIsVip &&
    isPlanSelectionEligible(state)
  ) {
    return promoCodeInitialState;
  }
  return promoCodeSlice;
}

/**
 * Checks if the user had a promo code persisted from a prior session.
 */
export function isPromoCodePersisted() {
  return getSessionState("browser").promoCodePersisted;
}

/**
 * Used for tracking if we currently apply a promo code from a prior session.
 */
export function isPromoCodeFromPriorSession(promoCodeApplied: string) {
  return (
    promoCodeApplied === getSessionState("browser").promoCodePersistedApplied
  );
}

export function resetPromoCodeAppliedFromPriorSession() {
  updateSessionState("browser", {
    promoCodePersistedApplied: null,
  });
}

export const { setPromoCode, clearPromoCode } = promoCode.actions;

export default promoCode;
