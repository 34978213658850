import i18n from "@i18n";

import { updateUserContext } from "@utils/api/tracker";

import {
  determinePlanOverride,
  isITunesPlansEligible,
  isGooglePlayPlansEligible,
  shouldAssignWeekBasedMedPlans,
} from "src/utils/userSegment/features";
import {
  calculateBMIMetric,
  calculateMetricWeightFromBMI,
} from "src/primitive/bmi";
import { isNursing } from "src/utils/pace/planTimeAdjustmentNursing";

import Unit, {
  formatUnit,
  getWeightDisplayUnit,
  UnitType,
  convertUnits,
  subtractUnits,
} from "./Unit";
import { convertKgToLb } from "../primitive/UnitUtils";
import { SurveyAnswersState } from "./redux/slices/surveyAnswers";
import {
  isCountry,
  isCountryImperial,
  isAffiliateWithoutTrial,
  isUS,
  isUK,
  isAppIosWinback,
} from "./userSegment";
import {
  getPlanByKey,
  isEligibleFor30DayTrial,
  has7DayTrial,
  NATIVE_ANDROID_PLAN_KEY_SUFFIX,
  NATIVE_IOS_14_TRIAL_PLAN_KEY_SUFFIX,
  NATIVE_IOS_PLAN_KEY_SUFFIX,
  getMedPlanKey,
} from "./plans";
import { shift_days, shift_months } from "./datetime";
import { getRouteId } from "./meristemContext";
import { getPersonalizedTargetDate } from "./pace";
import { getWeightFromDate } from "./weight/weightPacing";
import { BillingIntervalProto_Unit } from "@noom/noom-contracts/noom_contracts/billing/billing_interval";
import { getMedFlowByRoute, MED_FLOW } from "./medEligibility";

const MINIMUM_NORMAL_BMI = 18.5;
const MAXIMUM_NORMAL_BMI = 25;
const RECOMMENDED_WEIGHT_LOWER_BOUND_BMI = 19;
const RECOMMENDED_WEIGHT_UPPER_BOUND_BMI = 25;
const RECOMMENDED_WEIGHT_LOWER_BOUND_LIMIT_KG = 31.7; // 70lbs
const RECOMMENDED_WEIGHT_UPPER_BOUND_LIMIT_KG = 27.2; // 60lbs

interface RecommendedPlan {
  recommendedPlanDuration: number;
  courseDuration: number;
  MAX_MONTHLY_WEIGHTLOSS: number;
  adjustedTargetWeight?: number;
  adjustedTargetWeightKg?: number;
  weightLossGoal?: number;
  weightLossGoalKg?: number;
}

export function getPlanKeyAndDuration(
  recommendedPlanDuration: number,
  isMedPlan = false
): {
  planKey: string | number;
  planDuration: number;
  planDurationUnit: BillingIntervalProto_Unit;
} {
  if (!recommendedPlanDuration) {
    // this case only happened in cypress tests, where some answers were not available
    // so recommendedPlanDuration is NaN
    return {
      planKey: null,
      planDuration: null,
      planDurationUnit: null,
    };
  }

  let planDuration = recommendedPlanDuration;
  let planKey: number | string = planDuration;

  if (isMedPlan) {
    if (getMedFlowByRoute() === MED_FLOW.HRT) {
      return {
        planKey: getMedPlanKey(),
        planDuration: 12,
        planDurationUnit: "WEEKS",
      };
    }

    return {
      planKey: getMedPlanKey(),
      planDuration: shouldAssignWeekBasedMedPlans() ? 12 : 3,
      planDurationUnit: shouldAssignWeekBasedMedPlans() ? "WEEKS" : "MONTHS",
    };
  }

  // Use 7 day trial plans for US-baseline routes.
  if (has7DayTrial()) {
    planKey = `${planDuration}-7-DAY-TRIAL`;
  }

  if (isEligibleFor30DayTrial()) {
    planKey = `${planDuration}-30-DAY-TRIAL`;
  }

  if (isAffiliateWithoutTrial()) {
    planKey = `${planDuration}-affiliate`;
  }

  // NOTE(Eric): The key for new discounted 12 month plan is "12-149". Reset the planDuration to 12 months, so calculations work.
  if ((planDuration as unknown) === "12-149") {
    planDuration = 12;
  }

  // NOTE(Rose): For certain routes the plan are overridden.
  // TODO(George): Check if this mechanism is legacy.
  if (determinePlanOverride()) {
    planKey = determinePlanOverride();
  }

  // iOS uses a plan key specific for mobile IAP plans
  if (isITunesPlansEligible()) {
    const hasIOS14DayTrial = !isUS() && isAppIosWinback();
    planKey = `${planDuration}${
      hasIOS14DayTrial
        ? NATIVE_IOS_14_TRIAL_PLAN_KEY_SUFFIX
        : NATIVE_IOS_PLAN_KEY_SUFFIX
    }`;
  }

  // Android uses a plan key specific for mobile IAP plans
  // This is currently disabled because Android was exempt from Play Store IAP policies
  if (isGooglePlayPlansEligible()) {
    planKey = `${planDuration}${NATIVE_ANDROID_PLAN_KEY_SUFFIX}`;
  }

  const isFreeTrialRoute = getRouteId() === "exft";
  if (isFreeTrialRoute && isUK()) {
    planKey = `${planDuration}-betterhealth`;
  }

  return { planKey, planDuration, planDurationUnit: "MONTHS" };
}

export async function getRecommendedPlan({
  recommendedPlanDuration,
  isMedPlan = false,
}: {
  recommendedPlanDuration: number;
  isMedPlan?: boolean;
}) {
  const { planKey, planDuration } = getPlanKeyAndDuration(
    recommendedPlanDuration,
    isMedPlan
  );
  if (!planKey) {
    return {
      recommendedPlan: null,
      planDuration: null,
    };
  }
  const recommendedPlan = await getPlanByKey(planKey);
  return { recommendedPlan, planDuration };
}

export function calculateRecommendedPlan(surveyAnswers: SurveyAnswersState) {
  const targetsAndGoals = calculateBMI(surveyAnswers);
  const planDetails = recommendPlan(surveyAnswers);
  if (planDetails.weightLossGoal > 0) {
    updateUserContext({
      weightLossGoalLb: planDetails.weightLossGoal,
    });
  } else {
    updateUserContext({
      weightLossGoalKg: planDetails.weightLossGoalKg,
    });
  }
  const { currentBMI, targetBMI } = targetsAndGoals;
  return { ...planDetails, currentBMI, targetBMI };
}

function recommendPlanImperialCA(currentWeight: number, targetWeight: number) {
  // Adjust the plan recommendation based on the weight loss goal.
  const payload: RecommendedPlan = {
    recommendedPlanDuration: 4,
    courseDuration: 4,
    MAX_MONTHLY_WEIGHTLOSS: 10,
    adjustedTargetWeight: targetWeight,
    weightLossGoal: 0,
  };
  const weightLossGoal = currentWeight - targetWeight;
  payload.weightLossGoal = weightLossGoal;

  if (weightLossGoal > 0 && weightLossGoal <= 20) {
    payload.recommendedPlanDuration = 2;
    payload.courseDuration = 2;
  } else if (weightLossGoal > 20 && weightLossGoal <= 38) {
    payload.recommendedPlanDuration = 4;
    payload.courseDuration = 4;
  } else if (weightLossGoal > 38 && weightLossGoal <= 58) {
    payload.recommendedPlanDuration = 6;
    payload.courseDuration = 6;
  } else if (weightLossGoal > 58 && weightLossGoal <= 90) {
    payload.recommendedPlanDuration = 8;
    payload.courseDuration = 8;
  } else if (weightLossGoal > 90) {
    payload.recommendedPlanDuration = 8;
    payload.courseDuration = Math.round(
      weightLossGoal / payload.MAX_MONTHLY_WEIGHTLOSS
    );
    payload.adjustedTargetWeight =
      currentWeight -
      payload.recommendedPlanDuration * payload.MAX_MONTHLY_WEIGHTLOSS;
  } else {
    // Handle case where user wants to lose weight.
    payload.recommendedPlanDuration = 4;
    payload.courseDuration = 4;
    payload.adjustedTargetWeight = currentWeight;
  }

  return payload;
}

function recommendPlanImperial(currentWeight: number, targetWeight: number) {
  // Adjust the plan recommendation based on the weight loss goal.
  const payload: RecommendedPlan = {
    recommendedPlanDuration: 4,
    courseDuration: 4,
    MAX_MONTHLY_WEIGHTLOSS: 10,
    adjustedTargetWeight: targetWeight,
    weightLossGoal: 0,
  };
  const weightLossGoal = currentWeight - targetWeight;
  payload.weightLossGoal = weightLossGoal;

  if (weightLossGoal > 0 && weightLossGoal <= 12) {
    payload.recommendedPlanDuration = 2;
    payload.courseDuration = 2;
  } else if (weightLossGoal > 12 && weightLossGoal <= 20) {
    payload.recommendedPlanDuration = 3;
    payload.courseDuration = 3;
  } else if (weightLossGoal > 20 && weightLossGoal <= 29) {
    payload.recommendedPlanDuration = 4;
    payload.courseDuration = 4;
  } else if (weightLossGoal > 29 && weightLossGoal <= 38) {
    payload.recommendedPlanDuration = 5;
    payload.courseDuration = 5;
  } else if (weightLossGoal > 38 && weightLossGoal <= 48) {
    payload.recommendedPlanDuration = 6;
    payload.courseDuration = 6;
  } else if (weightLossGoal > 48 && weightLossGoal <= 58) {
    payload.recommendedPlanDuration = 7;
    payload.courseDuration = 7;
  } else if (weightLossGoal > 58 && weightLossGoal <= 90) {
    payload.recommendedPlanDuration = 8;
    payload.courseDuration = 8;
  } else if (weightLossGoal > 90) {
    payload.recommendedPlanDuration = 8;
    payload.courseDuration = Math.round(
      weightLossGoal / payload.MAX_MONTHLY_WEIGHTLOSS
    );
    payload.adjustedTargetWeight =
      currentWeight -
      payload.recommendedPlanDuration * payload.MAX_MONTHLY_WEIGHTLOSS;
  } else {
    // Handle case where user wants to lose weight.
    payload.recommendedPlanDuration = 4;
    payload.courseDuration = 4;
    payload.adjustedTargetWeight = currentWeight;
  }

  return payload;
}

function recommendPlanNursingImperial(
  currentWeight: number,
  targetWeight: number
) {
  // Adjust the plan recommendation based on the weight loss goal.
  const payload: RecommendedPlan = {
    recommendedPlanDuration: 4,
    courseDuration: 4,
    MAX_MONTHLY_WEIGHTLOSS: 4,
    adjustedTargetWeight: targetWeight,
    weightLossGoal: 0,
  };
  const weightLossGoal = currentWeight - targetWeight;
  payload.weightLossGoal = weightLossGoal;

  if (weightLossGoal > 0 && weightLossGoal <= 9) {
    payload.recommendedPlanDuration = 2;
    payload.courseDuration = 2;
  } else if (weightLossGoal > 9 && weightLossGoal <= 13) {
    payload.recommendedPlanDuration = 3;
    payload.courseDuration = 3;
  } else if (weightLossGoal > 13 && weightLossGoal <= 17) {
    payload.recommendedPlanDuration = 4;
    payload.courseDuration = 4;
  } else if (weightLossGoal > 17 && weightLossGoal <= 21) {
    payload.recommendedPlanDuration = 5;
    payload.courseDuration = 5;
  } else if (weightLossGoal > 21 && weightLossGoal <= 25) {
    payload.recommendedPlanDuration = 6;
    payload.courseDuration = 6;
  } else if (weightLossGoal > 25 && weightLossGoal <= 29) {
    payload.recommendedPlanDuration = 7;
    payload.courseDuration = 7;
  } else if (weightLossGoal > 29 && weightLossGoal <= 33) {
    payload.recommendedPlanDuration = 8;
    payload.courseDuration = 8;
  } else if (weightLossGoal > 33 && weightLossGoal <= 37) {
    payload.recommendedPlanDuration = 8;
    payload.courseDuration = 9;
  } else if (weightLossGoal > 37 && weightLossGoal <= 41) {
    payload.recommendedPlanDuration = 8;
    payload.courseDuration = 10;
  } else if (weightLossGoal > 41 && weightLossGoal <= 45) {
    payload.recommendedPlanDuration = 8;
    payload.courseDuration = 11;
  } else if (weightLossGoal > 45) {
    payload.recommendedPlanDuration = 8;
    payload.courseDuration = Math.round(
      weightLossGoal / payload.MAX_MONTHLY_WEIGHTLOSS
    );
    payload.adjustedTargetWeight = currentWeight - 50;
  } else {
    // Handle case where user wants to lose weight.
    payload.recommendedPlanDuration = 4;
    payload.courseDuration = 4;
    payload.adjustedTargetWeight = currentWeight;
  }

  return payload;
}

function calculateAndTrackBMIMetric(
  heightCm: number,
  currentWeightKg: number,
  targetWeightKg: number,
  trackResults = true
) {
  const currentBMI = calculateBMIMetric(heightCm, currentWeightKg);
  const targetBMI = calculateBMIMetric(heightCm, targetWeightKg);

  let BMILevel = i18n.t("plans:belowNormal");
  if (currentBMI >= MINIMUM_NORMAL_BMI && currentBMI < MAXIMUM_NORMAL_BMI) {
    BMILevel = i18n.t("plans:normal");
  } else if (currentBMI >= MAXIMUM_NORMAL_BMI) {
    BMILevel = i18n.t("plans:aboveNormal");
  }

  // Store info as Mixpanel people properties.
  if (trackResults) {
    updateUserContext({
      currentBMI,
      targetBMI,
      heightCm,
    });
  }

  return {
    currentBMI,
    targetBMI,
    BMILevel,
  };
}

/**
 * @param bodyInformation - Information about the body (height, weight)
 * @param extractedBMI - This needs to have one of the values "targetBMI" or "currentBMI". Use CURRENT_BMI and TARGET_BMI for ensure this
 */
export function isHealthyBMIMetric(
  bodyInformation: SurveyAnswersState,
  extractedBMI: "currentBMI" | "targetBMI"
) {
  const { heightCm, weightKg, idealWeightKg } = bodyInformation;
  const targetBMI = calculateAndTrackBMIMetric(
    heightCm,
    weightKg,
    idealWeightKg,
    false
  )[extractedBMI];
  return targetBMI >= MINIMUM_NORMAL_BMI;
}

function recommendPlanMetric(currentWeightKg: number, targetWeightKg: number) {
  // Adjust the plan recommendation based on the weight loss goal.
  const payload: RecommendedPlan = {
    recommendedPlanDuration: 4,
    courseDuration: 4,
    MAX_MONTHLY_WEIGHTLOSS: 4.5,
    adjustedTargetWeightKg: targetWeightKg,
    weightLossGoalKg: 0,
  };

  const weightLossGoalKg = currentWeightKg - targetWeightKg;
  payload.weightLossGoalKg = weightLossGoalKg;

  if (weightLossGoalKg > 0 && weightLossGoalKg <= 9) {
    payload.recommendedPlanDuration = 2;
    payload.courseDuration = 2;
  } else if (weightLossGoalKg > 9 && weightLossGoalKg <= 18) {
    payload.recommendedPlanDuration = 4;
    payload.courseDuration = 4;
  } else if (weightLossGoalKg > 18 && weightLossGoalKg <= 27) {
    payload.recommendedPlanDuration = 6;
    payload.courseDuration = 6;
  } else if (weightLossGoalKg > 27 && weightLossGoalKg <= 41) {
    payload.recommendedPlanDuration = 8;
    payload.courseDuration = 8;
  } else if (weightLossGoalKg > 41) {
    payload.recommendedPlanDuration = 8;
    payload.courseDuration = Math.round(
      weightLossGoalKg / payload.MAX_MONTHLY_WEIGHTLOSS
    );
    payload.adjustedTargetWeightKg =
      currentWeightKg -
      payload.recommendedPlanDuration * payload.MAX_MONTHLY_WEIGHTLOSS;
  } else {
    // Handle case where user wants to lose weight.
    payload.recommendedPlanDuration = 4;
    payload.courseDuration = 4;
    payload.adjustedTargetWeightKg = currentWeightKg;
  }

  return payload;
}

/**
 * Returns weight and display weight, targetWeight and displayTargetWeight
 * depending if is US or NON_US (if UK could have STONES)
 * If unit is STONES (for UK), the display value will be the converted value
 */
export function getWeightInformation(surveyAnswers: SurveyAnswersState) {
  const planDetails = recommendPlan(surveyAnswers);
  const weightDisplayUnit = getWeightDisplayUnit(surveyAnswers);
  let weightUnit: UnitType;
  let weightValue: number;

  if (isCountryImperial()) {
    weightUnit = Unit.POUND;
    weightValue = surveyAnswers.weight;
  } else {
    weightUnit = Unit.KILOGRAM;
    weightValue = surveyAnswers.weightKg;
  }

  const targetWeight =
    planDetails.adjustedTargetWeight || planDetails.adjustedTargetWeightKg;

  const targetWeightDisplay = convertUnits(
    { mainUnitValue: targetWeight },
    weightUnit,
    weightDisplayUnit
  );

  // Make sure that we're always returning the values in kg as well
  const weightValueKg: number = surveyAnswers.weightKg;
  const targetWeightKg = convertUnits(
    { mainUnitValue: targetWeight },
    weightUnit,
    Unit.KILOGRAM
  ).mainUnitValue;

  return {
    weightValue,
    weightValueKg,
    weightUnit,
    weightDisplayUnit,
    targetWeight,
    targetWeightKg,
    targetWeightDisplay,
  };
}

export function calculateBMI(surveyAnswers: SurveyAnswersState) {
  const { heightCm, weightKg, idealWeightKg } = surveyAnswers;
  return calculateAndTrackBMIMetric(heightCm, weightKg, idealWeightKg);
}

export function recommendPlan(
  surveyAnswers: SurveyAnswersState
): RecommendedPlan {
  const { weightKg, idealWeightKg } = surveyAnswers;

  if (isCountryImperial()) {
    const weight = Math.round(
      convertKgToLb({ mainUnitValue: weightKg }).mainUnitValue
    );
    const idealWeight = Math.round(
      convertKgToLb({
        mainUnitValue: idealWeightKg,
      }).mainUnitValue
    );

    if (isCountry(["CA"])) {
      return recommendPlanImperialCA(weight, idealWeight);
    }

    return isNursing(surveyAnswers)
      ? recommendPlanNursingImperial(weight, idealWeight)
      : recommendPlanImperial(weight, idealWeight);
  }
  return recommendPlanMetric(weightKg, idealWeightKg);
}

/**
 * Returns the recommended plan duration (months until weight-loss goal reached).
 */
export function getCourseDuration(surveyAnswers: SurveyAnswersState) {
  return recommendPlan(surveyAnswers).recommendedPlanDuration;
}

/**
 * Returns a boolean value indicating whether or not to use imperial units to display weight/height information based on
 * the ideal weight unit from the survey state or country code. The ideal weight unit has higher priority than the country code.
 *
 * NOTE: This should not be used to determine whether or not to read the weight/height information in metric or imperial units.
 *
 * @param surveyAnswers The survey state.
 */
export function shouldDisplayImperialUnits(surveyAnswers?: SurveyAnswersState) {
  const weightDisplayUnit = getWeightDisplayUnit(surveyAnswers);
  return weightDisplayUnit === Unit.STONE || weightDisplayUnit === Unit.POUND;
}

/**
 * Calculates the recommended upper and lower bounds for ideal weight given user weight and height
 *
 * @param surveyAnswers SurveyAnswersState
 * @param countryCode Two letter country code representing the country.
 * @param selectedWeightUnit String representing the weight unit selected by the user.
 * @returns Object containing recommended upper and lower bounds for ideal weight.
 */
export function calculateRecommendedWeightRange(
  surveyAnswers: SurveyAnswersState,
  selectedWeightUnit: UnitType
) {
  const { currentBMI } = surveyAnswers;
  // Return undefined range if the user is already below the upper bound for recommended weight
  if (currentBMI < RECOMMENDED_WEIGHT_UPPER_BOUND_BMI) {
    return {
      lowerRangeRecommendedWeight: undefined,
      upperRangeRecommendedWeight: undefined,
    };
  }

  const { weightKg, heightCm } = surveyAnswers;

  let recommendedWeightLowerBound = calculateMetricWeightFromBMI(
    heightCm,
    RECOMMENDED_WEIGHT_LOWER_BOUND_BMI
  );
  let recommendedWeightUpperBound = calculateMetricWeightFromBMI(
    heightCm,
    RECOMMENDED_WEIGHT_UPPER_BOUND_BMI
  );

  // NOTE(norbert): we don't want to be too aggressive if we max on the plan duration
  if (
    recommendedWeightUpperBound <
    weightKg - RECOMMENDED_WEIGHT_UPPER_BOUND_LIMIT_KG
  ) {
    recommendedWeightLowerBound =
      weightKg - RECOMMENDED_WEIGHT_LOWER_BOUND_LIMIT_KG;
    recommendedWeightUpperBound =
      weightKg - RECOMMENDED_WEIGHT_UPPER_BOUND_LIMIT_KG;
  }

  const lowerBoundInDynamicUnit = convertUnits(
    { mainUnitValue: recommendedWeightLowerBound },
    Unit.KILOGRAM,
    selectedWeightUnit
  );

  const upperBoundInDynamicUnit = convertUnits(
    { mainUnitValue: recommendedWeightUpperBound },
    Unit.KILOGRAM,
    selectedWeightUnit
  );

  return {
    lowerRangeRecommendedWeight: formatUnit(
      lowerBoundInDynamicUnit,
      selectedWeightUnit,
      true
    ),
    upperRangeRecommendedWeight: formatUnit(
      upperBoundInDynamicUnit,
      selectedWeightUnit,
      true
    ),
  };
}

// Calculates the estimated weight loss a user will see halfway into their plan duration accounting for their localized/ideal weight unit
// and max weight loss per week. This halfway weight loss is shown as "bright spot" on the first update graph
export function calculateHalfwayWeightLoss(
  planDuration: number,
  graphIndex: number,
  surveyAnswers: SurveyAnswersState
) {
  const halfwayMonthNumber = planDuration / 2;

  let startingWeight;
  let idealWeight;
  let maxWeightLoss;
  if (isCountryImperial()) {
    startingWeight = surveyAnswers.weight;
    idealWeight = surveyAnswers.idealWeight;
    maxWeightLoss = 80;
  } else {
    startingWeight = surveyAnswers.weightKg;
    idealWeight = surveyAnswers.idealWeightKg;
    maxWeightLoss = 36;
  }

  // If the weight loss is more than the max weight loss we allow, reset the ideal weight to starting weight minus max weight loss.
  if (startingWeight - idealWeight > maxWeightLoss) {
    idealWeight = startingWeight - maxWeightLoss;
  }

  // Because we push the plan duration out by 40 days and count down 14 days by the first update graph, if the current date plus 26 days results in a new month,
  // we need to push the perceived plan duration out by 1 month to account for the fact that the first update graph will show an extra month duration for the user.
  const now = new Date();
  if (shift_days(26).getMonth() !== now.getMonth()) {
    // eslint-disable-next-line no-param-reassign
    planDuration += 1;
  }

  const targetDateFromPace = getPersonalizedTargetDate(
    graphIndex,
    surveyAnswers
  );

  const halfwayPlanDate = new Date(
    shift_months(halfwayMonthNumber, new Date()).getTime()
  );
  const halfwayWeight = getWeightFromDate(
    startingWeight,
    idealWeight,
    halfwayPlanDate,
    targetDateFromPace
  );

  const { weightUnit, weightDisplayUnit } = getWeightInformation(surveyAnswers);

  // Compute the weight difference between halfway weight and starting weight and take localized/ideal weight unit into account
  const weightDifference = subtractUnits(
    startingWeight,
    halfwayWeight,
    weightUnit,
    weightDisplayUnit
  );
  return formatUnit(weightDifference, weightDisplayUnit);
}
