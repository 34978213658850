import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

export const failUpgradeExecution = createAction<UpgradeState["errorStatus"]>(
  "upgrade/failUpgradeExecution"
);

export const closeUpgradeErrorModal = createAction("upgrade/closeErrorModal");

export interface UpgradeState {
  showUpgradeErrorModal: boolean;
  errorStatus?: number;
}

const initialState: UpgradeState = {
  showUpgradeErrorModal: false,
};

const upgradeSlice = createSlice({
  name: "upgrade",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        failUpgradeExecution,
        (state, action: PayloadAction<UpgradeState["errorStatus"]>) => ({
          ...state,
          showUpgradeErrorModal: true,
          errorStatus: action.payload,
        })
      )
      .addCase(closeUpgradeErrorModal, (state) => ({
        ...state,
        showUpgradeErrorModal: false,
        errorStatus: undefined,
      }));
  },
});

export default upgradeSlice;
