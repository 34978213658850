import i18n from "src/locales";
import {
  CountryCode,
  getAppCountryCode,
  getCountryCode,
  getLanguage,
  getMeristemContext,
} from "../meristemContext";
import getStore from "@utils/redux/store";

export const isEnLanguage = () => {
  return getLanguage() === "en";
};

export const isEnglishLanguageCounterOfferDropdown = () => {
  return (
    i18n.resolvedLanguage === "en" ||
    (i18n.resolvedLanguage && i18n.resolvedLanguage.startsWith("en")) // it could be en-US when first loaded
  );
};

export const isDeLanguage = () => {
  return getLanguage() === "de";
};

export const isEsLanguage = () => {
  return getLanguage() === "es";
};

export const isAU = () => {
  return getCountryCode() === "AU";
};

export const isUS = () => {
  return getCountryCode() === "US";
};

export const isCA = () => {
  return getCountryCode() === "CA";
};

export const isUK = () => {
  return getCountryCode() === "UK" || getCountryCode() === "GB";
};

export const isIE = () => {
  return getCountryCode() === "IE";
};

export const isNZ = () => {
  return getCountryCode() === "NZ";
};

export const isDE = () => {
  return getCountryCode() === "DE";
};

export const isEU = () => {
  return getMeristemContext().is_eu_citizen;
};

export const isIntl = () => {
  return !isUS();
};

export const isCountry = (countries: CountryCode[]) => {
  return countries.includes(getCountryCode());
};

/**
 * Feet and pounds
 */
export const isCountryImperial = () => {
  return isCountry(["US", "CA"]);
};

/**
 * Kilos or stones and feet or meters
 */
export const isCountryBritishUnits = () => {
  return isUK() || isCountry(["IE"]);
};

/**
 * Kilos and meters
 */
export const isCountryMetric = () => {
  return !isCountryImperial() && !isCountryBritishUnits();
};

export const usesCapitalizedNouns = () => {
  return isDeLanguage();
};

export const isEnProgramLocale = (state = getStore().getState()) => {
  const { program_locale } = state.serverContext;
  return !!program_locale?.startsWith("en");
};

export const isAppUS = () => {
  const appCountryCode = getAppCountryCode();
  return appCountryCode === "US";
};

export const isAppCA = () => {
  const appCountryCode = getAppCountryCode();
  return appCountryCode === "CA";
};

export const isAppAU = () => {
  const appCountryCode = getAppCountryCode();
  return appCountryCode === "AU";
};
