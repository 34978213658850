import React, { useRef } from "react";
import i18n from "@i18n";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { compassColors } from "@utils/styling";
import { useTrackInViewport } from "src/hooks/viewport";
import facebook from "./images/footer_facebook_icon_2.png";
import instagram from "./images/footer_instagram_icon_2.png";
import linkedin from "./images/footer_linkedin_icon_2.png";
import twitter from "./images/footer_twitter_icon_2.png";
import { trackEvent } from "src/utils/api/tracker";
import { breakpointMobileExtended } from "src/design-system/styles/breakpoints";
import { LegalLinks } from "./LegalLinks";
import { capitalizeFirstLetter } from "src/primitive/string";

type Sites = {
  id: string;
  logo: string;
};

const sites: Sites[] = [
  {
    id: "facebook",
    logo: facebook,
  },
  {
    id: "instagram",
    logo: instagram,
  },
  {
    id: "twitter",
    logo: twitter,
  },
  {
    id: "linkedin",
    logo: linkedin,
  },
];

const SocialMediaLinks = () => (
  <>
    {sites.map(({ id, logo }) => (
      <a
        key={id}
        rel="nofollow noopener noreferrer"
        target="_blank"
        css={css`
          margin-right: 15px;
        `}
        href={i18n.t(`footer:${id}Link`)}
        onClick={() =>
          trackEvent(`Clicked${capitalizeFirstLetter(id)}FooterLink`)
        }
      >
        <img
          src={logo}
          css={css`
            vertical-align: bottom;
          `}
          height={20}
          alt={`${id}-logo`}
        />
      </a>
    ))}
  </>
);

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${compassColors.blueberry};
  margin: 0;
  padding: 20px 16px;
`;

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  row-gap: 20px;
  width: 100%;

  @media ${breakpointMobileExtended} {
    flex-wrap: wrap;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  align-self: flex-start;

  @media ${breakpointMobileExtended} {
    width: 100%;
    align-items: center;
  }
`;

const fontFooterText = css`
  font-size: 14px;
  font-weight: 300;
`;

const CopyrightText = styled.p`
  ${fontFooterText}

  flex-grow: 1;
  padding: 0;
  color: ${compassColors.white};
  white-space: nowrap;
  margin: 0;
`;

const CopyrightTerms = styled.div`
  ${fontFooterText}
  color: ${compassColors.white};

  margin: 0;
  padding: 0;
  text-decoration: underline;
  text-align: right;

  @media ${breakpointMobileExtended} {
    text-align: center;
  }
`;
const SocialMediaContainer = styled.div`
  margin-top: 20px;
`;

const AddressContainer = styled.div`
  margin-top: 15px;
  text-align: right;
  ${fontFooterText}
  color: #ffffff;
  @media ${breakpointMobileExtended} {
    margin-top: 0;
    text-align: center;
  }
`;

/**
 * Smaller version of the footer shown on the landing page.
 */
const FooterLandingSmall = () => {
  const copyrightTermsRef = useRef(null);
  useTrackInViewport(copyrightTermsRef, "ScrolledToFooterNav");

  const currentYear = new Date().getFullYear();
  return (
    <Footer>
      <MainContent>
        <Columns>
          <Column>
            <CopyrightText>
              {i18n.t("footer:copyright", { currentYear })}
            </CopyrightText>
          </Column>
          <Column>
            <CopyrightTerms ref={copyrightTermsRef}>
              <LegalLinks padding={5} includeExtraFooterLinks />
            </CopyrightTerms>
          </Column>
        </Columns>
        <Columns>
          <Column>
            <SocialMediaContainer>
              <SocialMediaLinks />
            </SocialMediaContainer>
          </Column>
          <Column>
            <AddressContainer>
              <div>One Palmer Square, Suite 441, Princeton, NJ 08452</div>
              <div
                css={css`
                  margin-top: 5px;
                `}
              >
                (888) 266-5071
              </div>
            </AddressContainer>
          </Column>
        </Columns>
      </MainContent>
    </Footer>
  );
};

export default FooterLandingSmall;
