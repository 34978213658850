import { MED_SKU } from "src/utils/medEligibility";
import {
  isBrandedPlanIneligible,
  isCompoundedPlanIneligible,
  isEligibleForClinicalBuyflow,
  isOralsMedPaidTraffic,
  isOralsPlanIneligible,
  isZepboundVialPlanIneligible,
} from "src/utils/userSegment/features";
import { getSurveyAnswers } from "src/hooks/survey/answers";
import { TreatmentOptions } from "@components/mht/utils/TreatmentOptions";

export function getMedRecommendedPlanTier(): MED_SKU | undefined {
  if (!isEligibleForClinicalBuyflow()) {
    return undefined;
  }

  if (!isZepboundVialPlanIneligible()) {
    return MED_SKU.BRANDED_ZEPBOUND_VIAL;
  }
  if (!isOralsPlanIneligible() && isOralsMedPaidTraffic()) {
    return MED_SKU.ORALS_METFORMIN;
  }
  if (!isCompoundedPlanIneligible()) {
    return MED_SKU.COMPOUNDED;
  }
  if (!isOralsPlanIneligible()) {
    return MED_SKU.ORALS_METFORMIN;
  }
  if (!isBrandedPlanIneligible()) {
    return MED_SKU.BRANDED;
  }

  return undefined;
}

const HRT_TREATMENTS_MAP = {
  [TreatmentOptions.HORMONAL_CREAM_OR_GEL]: MED_SKU.HRT_CREAM,
  [TreatmentOptions.ESTROGEN_PATCH]: MED_SKU.HRT_PATCH,
};

export function getHRTRecommendedPlanTier() {
  const {
    takingHRT,
    previousHRTTreatment,
    previousHRTPreference,
    currentHRTTreatment,
    currentHRTPreference,
    previouslyTakenHRT,
  } = getSurveyAnswers();

  let recommendedPlan = MED_SKU.HRT_CREAM;

  if (
    takingHRT?.includes("noNeverBefore") ||
    (takingHRT?.includes("no") && previouslyTakenHRT?.includes("no"))
  ) {
    return recommendedPlan;
  }

  const treatment = takingHRT?.includes("yes")
    ? currentHRTTreatment
    : previousHRTTreatment;
  const preference = takingHRT?.includes("yes")
    ? currentHRTPreference
    : previousHRTPreference;

  const usedTreatments =
    treatment &&
    Object.keys(HRT_TREATMENTS_MAP).filter((treatmentOption) =>
      treatment.includes(treatmentOption)
    );

  if (usedTreatments && usedTreatments.length === 1) {
    const oppositeTreatment = Object.keys(HRT_TREATMENTS_MAP).find(
      (key) => key !== usedTreatments[0]
    );

    recommendedPlan = preference.includes("yes")
      ? HRT_TREATMENTS_MAP[usedTreatments[0]]
      : HRT_TREATMENTS_MAP[oppositeTreatment];
  }

  return recommendedPlan;
}
