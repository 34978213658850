import { shift_days } from "@utils/datetime";
import { captureException } from "@utils/error";
import { NoomError } from "@utils/error/NoomError";

import { Plan, updatePlans } from "src/utils/redux/slices/plans";
import { trackEvent } from "src/utils/api/tracker";
import { send } from "src/utils/fetch";

import { ServerContextState } from "./redux/slices/serverContext";
import getStore from "./redux/store";
import {
  isAffiliateWithTrial,
  isClinicalRoute,
  isCoachReferralsRoute,
  isCore,
  isInApp,
  isUS,
  isWinback,
} from "./userSegment";
import { formatPriceWithCurrencySymbol } from "./priceUtils";
import saveToBraze from "./brazeUploader";
import {
  RecommendedPlanState,
  updateRecommendedPlan,
} from "./redux/slices/recommendedPlan";
import mapPlanKeyToNoomPlanId, {
  clinicalPlanIdPrefix,
  clinicalPlans,
  clinicalWeekPlans,
  hrtPlanIdPrefix,
  hrtPlans,
} from "./plansMapping";
import { prepareGrowthAPIParameters } from "./services/api-params";
import {
  getSelectedMedPlanType,
  isEligibleForStandaloneHRTBuyflow,
  isGooglePlayPlansEligible,
  isITunesPlansEligible,
  isOralsMedPaidTraffic,
  shouldAssignWeekBasedMedPlans,
} from "./userSegment/features";
import { getCountryCode, getMeristemContext } from "./meristemContext";
import { getSessionState } from "src/pageDefinitions/session";
import { getEligibleMedProducts, MED_SKU } from "./medEligibility";
import { TierTypeEnumProto_TierType } from "@noom/noom-contracts/noom_contracts/events/buyflow/buyflow";
import { DEFAULT_NW_PLAN_DURATION_CLINICAL } from "./constants";
import { BillingIntervalProto_Unit } from "@noom/noom-contracts/noom_contracts/billing/billing_interval";
import { getHRTRecommendedPlanTier } from "@components/med/planInformation/utils";

export const HM_CURRICULUM = "HM";
export const VIP_HM_CURRICULUM = "VIP_HM";
export const NATIVE_IOS_PLAN_KEY_SUFFIX = "-IN-APP-IOS";
export const NATIVE_IOS_14_TRIAL_PLAN_KEY_SUFFIX = "-IN-APP-IOS-14-TRIAL";
export const NATIVE_ANDROID_PLAN_KEY_SUFFIX = "-IN-APP-ANDROID";

// c/p logic from growth repo
const GEO_DATA_TO_CURRENCY = {
  // By countries.
  US: "USD",
  CA: "CAD",
  GB: "GBP",
  AU: "AUD",
  NZ: "NZD",
  JP: "JPY",
  KR: "KRW",
  // By continents.
  EU: "EUR",
  NA: "USD",
};

export const getUserCurrency = () => {
  const countryCode = getCountryCode();
  const { continent_code } = getMeristemContext();

  if (GEO_DATA_TO_CURRENCY[countryCode]) {
    return GEO_DATA_TO_CURRENCY[countryCode];
  }
  if (GEO_DATA_TO_CURRENCY[continent_code]) {
    return GEO_DATA_TO_CURRENCY[continent_code];
  }
  return GEO_DATA_TO_CURRENCY.US;
};

export const getNoomPlanIdFromKey = (key: string) => {
  const noomPlanId = mapPlanKeyToNoomPlanId[key];

  if (!noomPlanId) {
    // should not happen, we mapped all keys defined in growth
    trackEvent("CannotMapKeyToNoomPlanId", { key });
    throw new Error(`Missing plan key mapping for: ${key}`);
  }

  return noomPlanId;
};

export const getNoomPlanKeyFromId = (id: string | undefined) => {
  return Object.keys(mapPlanKeyToNoomPlanId).find(
    (key) => mapPlanKeyToNoomPlanId[key] === id
  );
};

export const isClinicalPlanKey = (planKey: string | undefined) => {
  const clinicalPlanKeys = [
    ...Object.values(clinicalPlans),
    ...Object.values(clinicalWeekPlans),
  ];
  return clinicalPlanKeys.includes(planKey);
};

export const isClinicalTierNoomPlanId = (noomPlanId: string | undefined) => {
  return isClinicalPlanKey(getNoomPlanKeyFromId(noomPlanId));
};

const isBrandedNoomPlanKey = (noomPlanKey: string) => {
  return [clinicalPlans.branded, clinicalWeekPlans.branded].includes(
    noomPlanKey
  );
};

export const isBrandedNoomPlanId = (noomPlanId: string | undefined) => {
  return isBrandedNoomPlanKey(getNoomPlanKeyFromId(noomPlanId));
};

const isCompoundedNoomPlanKey = (noomPlanKey: string) => {
  return [clinicalPlans.compounded, clinicalWeekPlans.compounded].includes(
    noomPlanKey
  );
};

export const isCompoundedNoomPlanId = (noomPlanId: string | undefined) => {
  return isCompoundedNoomPlanKey(getNoomPlanKeyFromId(noomPlanId));
};

const isOralsMetforminNoomPlanKey = (noomPlanKey: string) => {
  return [
    clinicalPlans.oralsMetformin,
    clinicalWeekPlans.oralsMetformin,
  ].includes(noomPlanKey);
};

export const isOralsMetforminNoomPlanId = (noomPlanId: string | undefined) => {
  return isOralsMetforminNoomPlanKey(getNoomPlanKeyFromId(noomPlanId));
};

export const isHRTPlanKey = (planKey: string | undefined) => {
  return Object.values(hrtPlans).includes(planKey);
};

export const isHRTNoomPlanId = (noomPlanId: string | undefined) => {
  return isHRTPlanKey(getNoomPlanKeyFromId(noomPlanId));
};

export const isMedTierNoomPlanId = (noomPlanId: string | undefined) => {
  return isClinicalTierNoomPlanId(noomPlanId) || isHRTNoomPlanId(noomPlanId);
};

const isHrtCreamPlanKey = (noomPlanKey: string | undefined) => {
  return hrtPlans.cream === noomPlanKey;
};

const isHrtPatchPlanKey = (noomPlanKey: string | undefined) => {
  return hrtPlans.patch === noomPlanKey;
};

export const isHRTCreamNoomPlanId = (noomPlanId: string | undefined) => {
  return isHrtCreamPlanKey(getNoomPlanKeyFromId(noomPlanId));
};

export const isHRTPatchNoomPlanId = (noomPlanId: string | undefined) => {
  return isHrtPatchPlanKey(getNoomPlanKeyFromId(noomPlanId));
};

/**
 * Determines the plan length using the plan id
 * The plans mapping keys contain the plan duration. The key has the following structure:
 * <plan_duration><separator><extra_plan_details>
 * <separator> can be "-" or "_"
 */
export const getNoomPlanDurationFromID = (
  noom_plan_id: string
): number | null => {
  const plan: [string, string] = Object.entries(mapPlanKeyToNoomPlanId).find(
    ([, plan_id]) => plan_id === noom_plan_id
  );

  if (!plan) {
    // should not happen, we mapped all keys defined in growth
    captureException(
      new NoomError("CannotMapNoomPlanIdToKey", { context: { noom_plan_id } })
    );
    return null;
  }
  const planDuration = plan[0] || "";
  const separator = planDuration.includes("-") ? "-" : "_";
  return +planDuration.split(separator)[0] || null;
};

export function getDefaultTrialFee(plan: RecommendedPlanState) {
  if (plan?.trial_item_options?.length > 0) {
    const prices = plan.trial_item_options.map((o) => o.price);
    const rawFee = Math.min(...prices);
    return rawFee < 1 && rawFee > 0 ? +rawFee.toFixed(2) : rawFee;
  }
  return 0;
}

export function getReferralTrialFee(plan: RecommendedPlanState) {
  const currencyMap = {
    USD: 10,
    CAD: 14,
    GBP: 8,
    AUD: 15,
    NZD: 17,
  };

  return currencyMap[plan.currency] ?? 0;
}

/**
 * Determine if user eligible for 7 day trial.
 */
export function has7DayTrial() {
  return (
    isUS() &&
    (isCore() ||
      isInApp() ||
      isWinback() ||
      isClinicalRoute() ||
      isCoachReferralsRoute())
  );
}

export function isEligibleFor30DayTrial() {
  const searchParams = new URLSearchParams(window.location.search);
  const trial_length = searchParams.get("trial_length");
  return isUS() && isAffiliateWithTrial() && trial_length === "30";
}

/**
 * Determine the length of user's trial for in trial users
 */
export function getTrialLength(serverContext: ServerContextState) {
  const subscriptions = JSON.parse(serverContext.subscriptions);
  const subscription = subscriptions.find((sub) => sub.status === "ACTIVE");
  if (subscription) {
    if (/32_day/.test(subscription.plan_id)) {
      return 32;
    }
    if (/22_day/.test(subscription.plan_id)) {
      return 22;
    }
    if (/8_day/.test(subscription.plan_id)) {
      return 8;
    }
  }
  return 15;
}

export function getMedTrialFeeInUSD(planKeyParam?: string) {
  const { recommendedPlan } = getStore().getState();

  const planKey =
    planKeyParam || getNoomPlanKeyFromId(recommendedPlan.noom_plan_id);

  if (isBrandedNoomPlanKey(planKey)) {
    return 69;
  }
  if (isCompoundedNoomPlanKey(planKey)) {
    return 149;
  }
  if (isOralsMetforminNoomPlanKey(planKey)) {
    return shouldUseTelex232Plans() ? 69 : 59;
  }
  if (isHrtCreamPlanKey(planKey)) {
    return 69;
  }
  if (isHrtPatchPlanKey(planKey)) {
    return 99;
  }
  return 0.5;
}

/**
 * Determine whether a plan is part of the HM curriculum
 */
export function isHMPlan(plan: Partial<Plan>) {
  return [HM_CURRICULUM, VIP_HM_CURRICULUM].includes(plan.curriculum);
}

export async function fetchProductPlanAsPlanById(noomPlanId: string) {
  const { dispatch, getState } = getStore();
  const state = getState();
  const currency = getUserCurrency();
  const planKey = `${noomPlanId}_${currency}`;

  const plan = state.plans[planKey];
  if (plan) {
    return plan;
  }

  try {
    const url = `/services/api/product_catalog/v1/productAsPlan/${encodeURIComponent(
      currency
    )}/${encodeURIComponent(noomPlanId)}`;

    const result = await send<Plan>("GET", url, null, {
      params: prepareGrowthAPIParameters(),
    });
    result.noom_plan_id = noomPlanId;

    dispatch(
      updatePlans({
        [planKey]: result,
      })
    );

    return result;
  } catch (e) {
    captureException(e);
    return null;
  }
}

export async function fetchProductPlanForExistingUser(
  noomPlanId: string,
  promoCode?: string,
  use45PercentDiscount = false
) {
  try {
    const url = `/api/plans/getPlanForExistingUser/${encodeURIComponent(
      noomPlanId
    )}`;

    const params = new URLSearchParams();
    if (promoCode) {
      params.set("promoCode", promoCode);
    }
    if (use45PercentDiscount) {
      params.set("useCounterOffer45Discount", "true");
    }

    const result = await send<
      Plan & {
        sales_tax_rate: number;
        is_tax_inclusive: boolean;
      }
    >("GET", url, null, {
      params: prepareGrowthAPIParameters(params),
    });
    result.noom_plan_id = noomPlanId;

    return result;
  } catch (e) {
    captureException(e);
    return null;
  }
}

async function fetchNativeAppPlan(key: string) {
  const { dispatch } = getStore();
  const currency = getUserCurrency();
  try {
    // backend returns a regular plan object but with one added property of itunes_product_id OR android_product_id
    const url = `/services/api/product_catalog/v1/nativeAppProductAsPlan/${encodeURIComponent(
      currency
    )}/${encodeURIComponent(key)}`;

    const result = await send<Plan>("GET", url, null, {
      params: prepareGrowthAPIParameters(),
    });
    result.noom_plan_id = key;

    dispatch(
      updatePlans({
        [`${key}_${currency}`]: result,
        [key]: result,
      })
    );

    dispatch(updateRecommendedPlan(result));

    return result;
  } catch (e) {
    captureException(e);
    return null;
  }
}

const keysToCheckForDiscrepancies = ["braintree_id", "price"];

export const getPlanByKey = async (key: string | number): Promise<Plan> => {
  // native app plans (Android, iOS) go through a different growth API call
  if (
    `${key}`.endsWith(NATIVE_IOS_PLAN_KEY_SUFFIX) ||
    `${key}`.endsWith(NATIVE_IOS_14_TRIAL_PLAN_KEY_SUFFIX) ||
    `${key}`.endsWith(NATIVE_ANDROID_PLAN_KEY_SUFFIX)
  ) {
    return fetchNativeAppPlan(`${key}`);
  }

  const oldPlan = getStore().getState()?.plans?.[key];
  const noomPlanId = getNoomPlanIdFromKey(`${key}`);
  const productPlanAsPlan = await fetchProductPlanAsPlanById(noomPlanId);

  // compare old plan and fetched plan
  const discrepancies = [];
  let status = "match";

  if (!productPlanAsPlan) {
    status = "notFound";
  } else if (oldPlan) {
    // use keys of an old plan, since transformed product plan will contain
    // additional properties (e.g. )
    // only run the comparison in landing context (payment context has no plans dict)
    Object.keys(oldPlan).forEach((k) => {
      if (
        keysToCheckForDiscrepancies.includes(k) &&
        oldPlan[k] !== productPlanAsPlan[k]
      ) {
        discrepancies.push(k);
        status = "discrepancy";
      }
    });
  }

  trackEvent("ProducPlanAsPlanSearch", {
    key,
    noomPlanId,
    oldPlan,
    productPlanAsPlan,
    status,
    discrepancies,
  });

  return productPlanAsPlan ?? oldPlan;
};

export async function savePlanToBraze(data: {
  recommendedPlan: RecommendedPlanState;
  currentBMI: number;
  targetBMI: number;
  targetMonthNameFull: string;
}) {
  let recommendedNWEmailPlan: RecommendedPlanState;
  let recommendedMedPlanId;
  let recommendedPlan7DaysNoomPlanId;

  if (isMedTierNoomPlanId(data.recommendedPlan?.noom_plan_id)) {
    recommendedMedPlanId = data.recommendedPlan?.noom_plan_id;
    // Note(Alex): Med users also get the regular NW emails so we need to fill the existing fields
    // with data related to a regular NW plan. For med related emails we are sending a standalone field.
    recommendedNWEmailPlan = await getPlanByKey(
      `${DEFAULT_NW_PLAN_DURATION_CLINICAL}`
    );
    recommendedPlan7DaysNoomPlanId = isUS()
      ? getNoomPlanIdFromKey(`${DEFAULT_NW_PLAN_DURATION_CLINICAL}-7-DAY-TRIAL`)
      : "";
  } else {
    switch (data.recommendedPlan.trial_duration) {
      case 0:
      case 14:
        recommendedNWEmailPlan = data.recommendedPlan;
        break;
      default:
        recommendedNWEmailPlan = await getPlanByKey(
          `${data.recommendedPlan.billing_cycle_duration}`
        );
    }

    // Native app plans cannot be purchased on web, reassign to web-eligible plans
    if (isITunesPlansEligible() || isGooglePlayPlansEligible()) {
      recommendedNWEmailPlan = await getPlanByKey(
        `${data.recommendedPlan.billing_cycle_duration}`
      );
    }
    recommendedPlan7DaysNoomPlanId = isUS()
      ? getNoomPlanIdFromKey(
          `${data.recommendedPlan.billing_cycle_duration}-7-DAY-TRIAL`
        )
      : "";
  }

  const formatPrice = (price: number) =>
    formatPriceWithCurrencySymbol(price, recommendedNWEmailPlan);

  const properties: JsonObject = {
    current_bmi: data.currentBMI?.toFixed(1),
    target_bmi: data.targetBMI?.toFixed(1),
    recommended_month_goal: data.targetMonthNameFull,
    recommended_plan_url: recommendedNWEmailPlan.noom_plan_id,
    recommended_plan_regular_price: formatPrice(
      recommendedNWEmailPlan.regular_price
    ),
    recommended_plan_regular_monthly_price: formatPrice(
      recommendedNWEmailPlan.regular_monthly_price
    ),
    recommended_plan_price: formatPrice(recommendedNWEmailPlan.price),
    recommended_plan_monthly_price: formatPrice(
      recommendedNWEmailPlan.monthly_price
    ),
    recommended_plan_duration: recommendedNWEmailPlan.billing_cycle_duration,
  };
  if (recommendedPlan7DaysNoomPlanId) {
    properties.recommended_plan_url_7day = recommendedPlan7DaysNoomPlanId;
  }
  if (recommendedMedPlanId) {
    properties.recommended_plan_url_med = recommendedMedPlanId;
  }

  if (recommendedNWEmailPlan.isForcedPlan) {
    properties.forcedNoomPlanId = recommendedNWEmailPlan.noom_plan_id;
  }

  if (recommendedNWEmailPlan.starter_fee) {
    properties.recommended_plan_starter_fee = formatPrice(
      recommendedNWEmailPlan.starter_fee
    );
  }
  return saveToBraze({ user: properties });
}

export const calculateFirstChargeDate = (trialDuration: number) => {
  return shift_days(trialDuration + 1, new Date());
};

export function isPremiumTierPlanId(planId?: string) {
  return planId?.startsWith("healthy_weight_premium");
}

// TODO: refactor to use billing API https://noomhq.atlassian.net/browse/GPA-723
export function isMedTierPlanId(planId: string) {
  if (!planId) return false;

  return Boolean(getCurrentMedTierSKU(planId));
}

// TODO: refactor to use billing API https://noomhq.atlassian.net/browse/GPA-723
export function isCompoundedMedTier(planId: string) {
  return planId?.startsWith(clinicalPlanIdPrefix.compounded);
}

export function isBrandedMedTier(planId: string) {
  return planId?.startsWith(clinicalPlanIdPrefix.branded);
}

export function isOralsMetforminMedTier(planId: string) {
  return planId?.startsWith(clinicalPlanIdPrefix.oralsMetformin);
}

export function isHRTCreamMedTier(planId: string) {
  return planId?.startsWith(hrtPlanIdPrefix.cream);
}

export function isHRTPatchMedTier(planId: string) {
  return planId?.startsWith(hrtPlanIdPrefix.patch);
}

export function isHRTMedTier(planId: string) {
  return isHRTCreamMedTier(planId) || isHRTPatchMedTier(planId);
}

export function getCurrentMedTierSKU(planId: string) {
  if (isCompoundedMedTier(planId)) {
    return MED_SKU.COMPOUNDED;
  }

  if (isBrandedMedTier(planId)) {
    return MED_SKU.BRANDED;
  }

  if (isOralsMetforminMedTier(planId)) {
    return MED_SKU.ORALS_METFORMIN;
  }

  if (isHRTCreamMedTier(planId)) {
    return MED_SKU.HRT_CREAM;
  }

  if (isHRTPatchMedTier(planId)) {
    return MED_SKU.HRT_PATCH;
  }

  return null;
}

export function getTierByPlanId(planId: string): TierTypeEnumProto_TierType {
  if (isMedTierPlanId(planId)) {
    return "MED";
  }
  if (isPremiumTierPlanId(planId)) {
    return "PREMIUM";
  }
  return "MAIN";
}

export function getMedPlanKeyBySKU(
  medSKU: MED_SKU
): keyof typeof clinicalPlans | keyof typeof hrtPlans {
  switch (medSKU) {
    case MED_SKU.COMPOUNDED:
      return "compounded";
    case MED_SKU.ORALS_METFORMIN:
      return "oralsMetformin";
    case MED_SKU.HRT_CREAM:
      return "cream";
    case MED_SKU.HRT_PATCH:
      return "patch";
    default:
      return "branded";
  }
}

export function getMedPlanKey() {
  let planKeyMap;
  const isEligibleForStandaloneHRT = isEligibleForStandaloneHRTBuyflow();

  if (isEligibleForStandaloneHRT) {
    planKeyMap = hrtPlans;
  } else if (shouldAssignWeekBasedMedPlans()) {
    planKeyMap = clinicalWeekPlans;
  } else planKeyMap = clinicalPlans;

  if (
    getSessionState("browser").selectedMedPlanType ||
    getSessionState("browser").selectedHRTPlanType
  ) {
    // If the user has explicitly selected a plan from the plan reveal pages, prioritize that
    const selectedPlanType = getSelectedMedPlanType();

    return planKeyMap[getMedPlanKeyBySKU(selectedPlanType)];
  }

  if (isEligibleForStandaloneHRT) {
    const recommendedSKU = getHRTRecommendedPlanTier();

    return hrtPlans[getMedPlanKeyBySKU(recommendedSKU)];
  }

  // Otherwise, assign the highest priority plan they're eligible for
  // This is either orals > compounded > branded or compounded > orals > branded
  // depending on the user type
  const eligibleProducts = getEligibleMedProducts();
  const eligibleForOrals = eligibleProducts.includes(MED_SKU.ORALS_METFORMIN);
  const eligibleForCompounded = eligibleProducts.includes(MED_SKU.COMPOUNDED);
  if (isOralsMedPaidTraffic() && eligibleForOrals) {
    return planKeyMap.oralsMetformin;
  }
  if (eligibleForCompounded) {
    return planKeyMap.compounded;
  }
  if (eligibleForOrals) {
    return planKeyMap.oralsMetformin;
  }
  return planKeyMap.branded;
}

export function getPlanDurationUnitTranslationKey(
  durationUnit: BillingIntervalProto_Unit
) {
  switch (durationUnit) {
    case "DAYS":
      return "day";
    case "WEEKS":
      return "week";
    default:
      return "month";
  }
}

export function shouldUseTelex232Plans() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("isEnrolledInTelex232") === "true";
}
