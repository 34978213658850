import { Survey } from "src/pageDefinitions/types";
import invariant from "tiny-invariant";
import { UserDataState } from "../redux/slices/userData";
import { isHM, isUS } from "../userSegment";

export function getInternalSurveyNamespace(
  pageSetSurveyNamespace: Survey["surveyNameSpace"]
) {
  // saveSurveyAnswers stores in userData slice. This check ensures that the JSON config
  // will properly load that data in the future/on refresh.
  invariant(
    pageSetSurveyNamespace.startsWith("userData.") ||
      pageSetSurveyNamespace === "mainSurvey",
    "surveyNameSpace must start with userData for this action."
  );

  let internalNamespaceName: keyof UserDataState | "mainSurvey" =
    pageSetSurveyNamespace.replace("userData.", "") as any;
  if (internalNamespaceName === "mainSurvey") {
    if (isHM()) {
      internalNamespaceName = "personaSurveyHM";
    } else if (!isUS()) {
      internalNamespaceName = "personaSurveyNonUS";
    } else {
      internalNamespaceName = "personaSurveyUS";
    }
  }
  return internalNamespaceName;
}
