import { ActionHandler } from "..";
import { onStartSurvey } from "./onStartSurvey";
import getStore from "src/utils/redux/store";
import { trackBuyflowEvent } from "src/utils/api/tracker";
import { trackCurrentTierEvent } from "src/utils/counterOffer";

export const onStartCancellationSurvey: ActionHandler = (actionConfig) => {
  onStartSurvey(actionConfig);
  const { serverContext } = getStore().getState();

  trackBuyflowEvent("CancellationSurveyStarted", {
    subscriptionId: serverContext.subscription_id,
  });

  trackCurrentTierEvent();
};
