import {
  BRANDED_PLAN_INELIGIBLE_HEALTH_RISKS,
  BrandedPlanIneligibleStates,
  COMPOUNDED_PLAN_INELIGIBLE_HEALTH_RISKS,
  CompoundedPlanIneligibleStates,
  MED_BMI_THRESHOLD_BRANDED,
  MED_BMI_THRESHOLD_COMPOUNDED,
  MED_BMI_THRESHOLD_ORALS,
  ORALS_PLAN_INELIGIBLE_HEALTH_RISKS,
  OralsPlanIneligibleStates,
  CompoundedPlanIncreasedBMIStates,
  MED_BMI_INCREASED_THRESHOLD_COMPOUNDED,
  MhtIneligibleStates,
  HRT_INELIGIBLE_HEALTH_RISKS,
  CLINICAL_INELIGIBLE_HEALTH_RISKS,
} from "src/components/refactored-survey/question-sets/insurance-survey-questions/utils/insuranceConstants";
import {
  isFlowEligibleForClinicalProducts,
  isFlowEligibleForHRTProducts,
  isZepboundFeatureEnabled,
} from "@utils/userSegment/features";
import { getSubdivision } from "./meristemContext";
import zipState from "zip-state";
import { calculateBMIImperial, calculateBMIMetric } from "src/primitive/bmi";
import {
  healthRiskOptions,
  oralsHealthRiskOptions,
} from "src/pages/survey/currentHealthRiskMedical";
import { getSurveyAnswers } from "src/hooks/survey/answers";
import { hrtHealthRiskOptions } from "src/pages/hrt-survey/currentHealthRiskHRT";
import { isHRTRoute } from "@utils/userSegment";

export enum MED_SKU {
  BRANDED_ZEPBOUND_VIAL = "BRANDED_ZEPBOUND_VIAL",
  BRANDED = "BRANDED",
  COMPOUNDED = "COMPOUNDED",
  ORALS_METFORMIN = "ORALS_METFORMIN",
  HRT_CREAM = "HRT_CREAM",
  HRT_PATCH = "HRT_PATCH",
}

export enum MED_FLOW {
  HRT = "hrt",
  CLINICAL = "clinical",
}

type UserInfo = {
  bmi: number;
  medicalHealthRisks: HealthRiskOption[];
  hrtHealthRisks: HealthRiskOption[];
  state: string;
};

export const ELIGIBLE_SKUS_FOR_SWITCH = [
  MED_SKU.COMPOUNDED,
  MED_SKU.ORALS_METFORMIN,
];

type HealthRiskOption =
  | typeof healthRiskOptions[number]
  | typeof oralsHealthRiskOptions[number]
  | typeof hrtHealthRiskOptions[number];

type EligibilityInfo = {
  flow: MED_FLOW;
  bmiThreshold?: number;
  ineligibleHealthRisks: HealthRiskOption[];
  ineligibleStates?: Record<string, string>;
  extaStateCheck?: (
    userInfo: UserInfo,
    eligibilityCheckOptions: EligibilityCheckOptions
  ) => boolean;
  extraEligibilityCheck?: () => boolean;
};

type EligibilityCheckOptions = {
  checkBMI: boolean;
  checkHealthRisks: boolean;
  checkState: boolean;
};

const ELIGIBILITY_INFO: Record<MED_SKU, EligibilityInfo> = {
  [MED_SKU.BRANDED_ZEPBOUND_VIAL]: {
    bmiThreshold: MED_BMI_THRESHOLD_BRANDED,
    ineligibleHealthRisks: [
      ...BRANDED_PLAN_INELIGIBLE_HEALTH_RISKS,
      ...CLINICAL_INELIGIBLE_HEALTH_RISKS,
    ],
    ineligibleStates: BrandedPlanIneligibleStates,
    extraEligibilityCheck: () =>
      isFlowEligibleForClinicalProducts() && isZepboundFeatureEnabled(),
    flow: MED_FLOW.CLINICAL,
  },
  [MED_SKU.BRANDED]: {
    bmiThreshold: MED_BMI_THRESHOLD_BRANDED,
    ineligibleHealthRisks: [
      ...BRANDED_PLAN_INELIGIBLE_HEALTH_RISKS,
      ...CLINICAL_INELIGIBLE_HEALTH_RISKS,
    ],
    ineligibleStates: BrandedPlanIneligibleStates,
    extraEligibilityCheck: () => isFlowEligibleForClinicalProducts(),
    flow: MED_FLOW.CLINICAL,
  },
  [MED_SKU.COMPOUNDED]: {
    bmiThreshold: MED_BMI_THRESHOLD_COMPOUNDED,
    ineligibleHealthRisks: [
      ...COMPOUNDED_PLAN_INELIGIBLE_HEALTH_RISKS,
      ...CLINICAL_INELIGIBLE_HEALTH_RISKS,
    ],
    ineligibleStates: CompoundedPlanIneligibleStates,
    extaStateCheck: ({ bmi, state }, { checkBMI }) => {
      if (state in CompoundedPlanIncreasedBMIStates && checkBMI) {
        return bmi >= MED_BMI_INCREASED_THRESHOLD_COMPOUNDED;
      }
      return true;
    },
    extraEligibilityCheck: () => isFlowEligibleForClinicalProducts(),
    flow: MED_FLOW.CLINICAL,
  },
  [MED_SKU.ORALS_METFORMIN]: {
    bmiThreshold: MED_BMI_THRESHOLD_ORALS,
    ineligibleHealthRisks: [
      ...ORALS_PLAN_INELIGIBLE_HEALTH_RISKS,
      ...CLINICAL_INELIGIBLE_HEALTH_RISKS,
    ],
    ineligibleStates: OralsPlanIneligibleStates,
    extraEligibilityCheck: () => isFlowEligibleForClinicalProducts(),
    flow: MED_FLOW.CLINICAL,
  },
  [MED_SKU.HRT_CREAM]: {
    ineligibleHealthRisks: HRT_INELIGIBLE_HEALTH_RISKS,
    extraEligibilityCheck: () => isFlowEligibleForHRTProducts(),
    ineligibleStates: MhtIneligibleStates,
    flow: MED_FLOW.HRT,
  },
  [MED_SKU.HRT_PATCH]: {
    ineligibleHealthRisks: HRT_INELIGIBLE_HEALTH_RISKS,
    extraEligibilityCheck: () => isFlowEligibleForHRTProducts(),
    ineligibleStates: MhtIneligibleStates,
    flow: MED_FLOW.HRT,
  },
};

function getBMI() {
  const { heightFeet, heightInch, weight, heightCm, weightKg } =
    getSurveyAnswers();
  if (
    heightFeet !== undefined &&
    heightInch !== undefined &&
    weight !== undefined
  ) {
    return calculateBMIImperial(heightFeet, heightInch, weight);
  }
  if (heightCm !== undefined && weightKg !== undefined) {
    return calculateBMIMetric(heightCm, weightKg);
  }
  return NaN;
}

export function passesMinBMIForMedSwitchingBuyflow() {
  const bmi = getBMI();

  return bmi >= MED_BMI_THRESHOLD_COMPOUNDED;
}

function getMedState() {
  const { zipcode } = getSurveyAnswers();
  // Note (Alex): Intially, we use the IP state as the source of truth for the state eligibility;
  // Once we collect the zipcode, we will only trust the state determined based on the zipcode
  let state = getSubdivision();
  if (zipcode !== undefined) {
    state = zipState(zipcode);
  }
  return state;
}

export function hasIneligibleHealthRisks(
  healthRisks: HealthRiskOption[],
  ineligibleHealthRisks: HealthRiskOption[]
) {
  const ineligibleAnswers = healthRisks.filter((answer) =>
    ineligibleHealthRisks.includes(answer)
  );

  return !!ineligibleAnswers.length;
}

export function getMedicalHealthRisks(): HealthRiskOption[] {
  const { currentHealthRiskMedical } = getSurveyAnswers();

  return (currentHealthRiskMedical || []).filter(
    (el) => typeof el === "string"
  ) as HealthRiskOption[];
}

export function getHrtHealthRisks(): HealthRiskOption[] {
  const { currentHealthRiskHRT } = getSurveyAnswers();

  return currentHealthRiskHRT || [];
}

export function getMedBMIThreshold() {
  let eligibilityKeys: Array<keyof typeof ELIGIBILITY_INFO> = [];
  // Get the products that pass the extra check, this means we don't
  // take into account the orals bmi threshold if it's disabled
  eligibilityKeys = eligibilityKeys.concat(
    getEligibleMedProducts({
      checkBMI: false,
      checkHealthRisks: false,
      checkState: false,
    })
  );
  const bmiThresholds = eligibilityKeys
    .map((key) => {
      const { bmiThreshold } = ELIGIBILITY_INFO[key];
      return bmiThreshold;
    })
    .filter(Boolean);
  return Math.min(...bmiThresholds);
}

function meetsEligibilityInfo(
  eligibilityInfo: EligibilityInfo,
  userInfo: UserInfo,
  eligibilityCheckOptions: EligibilityCheckOptions
) {
  const {
    bmiThreshold,
    ineligibleHealthRisks,
    ineligibleStates,
    extraEligibilityCheck,
    extaStateCheck,
  } = eligibilityInfo;
  const { bmi, medicalHealthRisks, hrtHealthRisks, state } = userInfo;
  const healthRisks =
    eligibilityInfo.flow === MED_FLOW.HRT ? hrtHealthRisks : medicalHealthRisks;
  const { checkBMI, checkHealthRisks, checkState } = eligibilityCheckOptions;

  const passesBmiCheck = checkBMI && bmiThreshold ? bmi >= bmiThreshold : true;
  const passesHealthRiskCheck =
    checkHealthRisks && ineligibleHealthRisks
      ? !hasIneligibleHealthRisks(ineligibleHealthRisks, healthRisks)
      : true;
  const passesStateCheck =
    checkState && ineligibleStates ? !(state in ineligibleStates) : true;
  const passesStateExtraCheck =
    checkState && extaStateCheck
      ? extaStateCheck(userInfo, eligibilityCheckOptions)
      : true;
  const passesExtraCheck = extraEligibilityCheck
    ? extraEligibilityCheck()
    : true;

  return (
    passesBmiCheck &&
    passesHealthRiskCheck &&
    passesStateCheck &&
    passesStateExtraCheck &&
    passesExtraCheck
  );
}

export function getEligibleMedProducts(
  options: EligibilityCheckOptions & {
    stateOverwrite?: string;
  } = {
    checkBMI: true,
    checkHealthRisks: true,
    checkState: true,
  },
  flow?: MED_FLOW
): Array<MED_SKU> {
  const eligibleProducts = [];
  const bmi = getBMI();
  const medicalHealthRisks = getMedicalHealthRisks();
  const hrtHealthRisks = getHrtHealthRisks();
  const state = options.stateOverwrite ?? getMedState();

  const userInfo = {
    bmi,
    medicalHealthRisks,
    hrtHealthRisks,
    state,
  };

  let eligibleSkus = Object.values(MED_SKU);
  if (flow) {
    eligibleSkus = eligibleSkus.filter(
      (sku) => ELIGIBILITY_INFO[sku].flow === flow
    );
  }
  eligibleSkus.forEach((sku) => {
    if (meetsEligibilityInfo(ELIGIBILITY_INFO[sku], userInfo, options)) {
      eligibleProducts.push(sku);
    }
  });

  return eligibleProducts;
}

export const getMedFlowByRoute = () => {
  if (isHRTRoute()) {
    return MED_FLOW.HRT;
  }
  return MED_FLOW.CLINICAL;
};
