import { trackEvent } from "src/utils/api/tracker";
import { send } from "src/utils/fetch";
import {
  getAppCountryCode,
  getCountryCode,
  getLanguage,
} from "../meristemContext";
import { createAppAsyncThunk } from "../redux/async-thunk";
import type {
  PurchaseFastForwardRequestProto_Body,
  PurchaseFastForwardResponseProto,
} from "@noom/noom-contracts/noom_contracts/growth/payment/purchase_service";
import { isEligibleForFreemiumUnidentifiedBuyflow } from "../userSegment/features";

const FAST_FORWARD_TRIAL_URL = "/api/payment/v3/fast_forward_trial/";

export const requestFastForwardTrial = createAppAsyncThunk(
  "fastForwardTrial/request",
  async (
    params: {
      discountOffered: boolean;
      upsellDiscountCode?: string;
    },
    thunkApi
  ) => {
    const { discountOffered, upsellDiscountCode } = params;
    const planDiscountApplied = discountOffered ? "Yes" : "No";
    const { serverContext, recommendedPlan } = thunkApi.getState();

    const noomPlanId = discountOffered
      ? recommendedPlan.noom_plan_id
      : serverContext.minimal_discount_noom_plan_id;
    const request: PurchaseFastForwardRequestProto_Body = {
      noomPlanId,
      trialFee: recommendedPlan.trial_fee,
      language: getLanguage(),
      countryCode: getCountryCode(),
      curriculumCountryCode: isEligibleForFreemiumUnidentifiedBuyflow()
        ? getAppCountryCode()
        : getCountryCode(),
      upsellDiscountCode: upsellDiscountCode ?? undefined,
    };

    const response = await send<PurchaseFastForwardResponseProto>(
      "POST",
      FAST_FORWARD_TRIAL_URL,
      request
    );
    trackEvent("SkipTrialAccepted", { planDiscountApplied });
    return response;
  }
);
