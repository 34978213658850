import { setUserIdInSentryScope } from "@utils/error/sentry";
import { loadServerContext, setCookiesFromMeristemContext } from "./context";
import { getEmailAndNameFromURLParams } from "./urlParams";
import { USER_IS_BOT } from "./botDetector";

import getStore from "./redux/store";
import { ServerContextState } from "./redux/slices/serverContext";
import {
  expectUserData,
  fetchAndInitializeExistingUser,
  initializeNewUser,
  updateUserData,
} from "./redux/slices/userData";
import { updateVisitorStatusState } from "./redux/slices/visitorStatus";
import { updateUserContext } from "./api/tracker";

import goto from "src/pageDefinitions/goto";
import { setInAppAutoCookie } from "./authCookieParser";
import {
  getCountryCode,
  getLanguage,
  getMeristemContext,
  getSubdivision,
} from "./meristemContext";
import * as userSegments from "./userSegment";
import * as featureSegments from "./userSegment/features";
import { initConversionTracker } from "./services/ConversionTracker";
import { clearPromoCode } from "./redux/slices/promoCode";
import { triggerLoadPhase } from "src/vendor";
import { getConsent } from "./consent";
import { pendingScripts } from "./scriptLoader";
import { clearCheckoutOfferTimerExpirationCookie } from "./checkoutTimer";
import { getSurveyAnswers } from "src/hooks/survey/answers";
import { measureTask } from "./timing";
import { init as urlParamsPiiInit } from "./urlParamsPii";
import visitTracker from "@noom/visit-tracker";
import getApiDomain from "src/utils/services/api-params";
import { appConfig } from "src/config";
import { logInfo } from "./monitoring/logging";
import { captureException } from "./error";
import { NoomError } from "./error/NoomError";
import { authenticateUser } from "src/test/quickSilver/components/deepLinksUtils";

/**
 * Update UserData slice (and possibly fetch an existing user's UserData).
 */
export async function updateUserDataSlice(serverContext: ServerContextState) {
  const store = getStore();

  // Fetch user from backend if they are in the database.
  if (expectUserData()) {
    await store.dispatch(fetchAndInitializeExistingUser(serverContext.user_id));
  } else {
    await store.dispatch(initializeNewUser(serverContext));
  }
  const emailAndName = getEmailAndNameFromURLParams();
  if (Object.keys(emailAndName).length !== 0)
    await store.dispatch(updateUserData(emailAndName));
}

/**
 * Track changes to the user's visitor status to mixpanel
 *
 * 1. Add tracking observer to the visitorStatus slice in Redux.
 * 2. Track initial state.
 */
function trackVisitorState() {
  // Update visitor status state on initialization to kick off VisitorStateDetermined tracking
  // Only update visitor status state on landing and payment to avoid unnecessary duplicate
  // visitorStateDetermined tracking events on post checkout contexts
  if (
    getMeristemContext().context_type === "landing" ||
    getMeristemContext().context_type === "payment-checkout"
  ) {
    getStore().dispatch(updateVisitorStatusState());
  }
}

function setInitialPeopleProperties() {
  const properties: JsonObject = {
    country: getCountryCode(),
    subdivision: getSubdivision(),
    language: getLanguage(),
  };
  if (USER_IS_BOT) properties.userIsBot = true;
  updateUserContext(properties);
}

function successCallback(text: string) {
  logInfo(text);
}

function errorCallback(error: Error) {
  captureException(
    new NoomError(`Visit tracking failed`, {
      cause: error,
      delegateMetric: true,
    })
  );
}

/**
 * Initialize redux, set up services, and fire off tracking calls.
 * @returns App initializes successfully
 */
export default async function initializeApplication() {
  // Expose debug tools
  (window as any).reduxState = getStore().getState;
  (window as any).grow = {
    getConsent,
    pendingScripts,
    setInAppAutoCookie,
    authenticateUser,
    goto,
    userSegments,
    featureSegments,
    clearPromoCode: () => getStore().dispatch(clearPromoCode()),
    clearCheckoutOfferTimerExpirationCookie,
    getSurveyAnswers,
  };

  // 1 - get the meristemContext data
  const meristemContext = getMeristemContext();
  setCookiesFromMeristemContext(meristemContext);

  // 2 - get serverContext from Django API
  const serverContext = await measureTask("serverContext", loadServerContext);

  // 5 - set up Sentry and attach user_id as a property
  setUserIdInSentryScope(serverContext.user_id);

  // 6 - Initialize visitTracker. This must happen before we fire any mixpanel events.
  visitTracker.init(
    appConfig.API_DOMAIN,
    getApiDomain(),
    successCallback,
    errorCallback
  );

  // 7 - update the UserData slice in redux (also, fetch userData if an existing user)
  await measureTask("userData", () => updateUserDataSlice(serverContext));

  // 8 - set up mixpanel tracker
  setInitialPeopleProperties();

  // 9 - track a visit
  visitTracker.createOrUpdateVisit();

  // 10 - set up a redux observer that updates visitor state
  trackVisitorState();

  initConversionTracker();

  // Before loading pixels, hide PII in the URL, since some pixels will send an event as soon as
  // they load which can include the current URL
  urlParamsPiiInit();

  triggerLoadPhase("after-context-init");
}
