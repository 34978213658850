import goto from "src/pageDefinitions/goto";
import { trackEvent } from "src/utils/api/tracker";
import { useOnce } from "./lifecycle";
import { useServerContext } from "./redux";
import { getAccessToken } from "src/utils/authCookieParser";

export function pathIsInSubscriptionCancelContext(
  path: string = window.location.pathname
) {
  const subscriptionContextRegex =
    /\/(subscription|ps)\/(cancel-trial|cancel-subscription|hm-cancel-trial|hm-cancel-subscription|med-downgrade)/;
  return subscriptionContextRegex.test(path);
}

export function useLegacyCounterOfferContextRedirect() {
  const serverContext = useServerContext();
  const isAuthenticated = !!getAccessToken();

  return useOnce(() => {
    trackEvent("RedirectLegacyCounterOffer", {
      isInTrial: serverContext.is_in_trial,
      isHM: serverContext.subscription_is_hm,
      isAuthenticated,
    });
    if (isAuthenticated) {
      goto.cancelSubscription(
        serverContext.subscription_is_hm,
        serverContext.is_in_trial
      );
    } else {
      goto.authenticationRedirect();
    }
  });
}
