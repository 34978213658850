import { ChangeEvent, useState } from "react";
import { send } from "src/utils/fetch";
import goto from "src/pageDefinitions/goto";
import {
  getInAppAutoCookie,
  setInAppAutoCookie,
} from "src/utils/authCookieParser";
import {
  saveMainSurveyAnswers,
  saveSurveyAnswers,
  setPurchaseSurveyAnswers,
  setHRTSurveyAnswers,
} from "src/utils/redux/slices/userData";
import getStore from "src/utils/redux/store";
import { useCallbackRef } from "src/hooks/lifecycle";

import {
  getPayloadForGlp1CompanionUser,
  getPayloadForMedUser,
  getPayloadForMhtUser,
  getPayloadForHRTCheckout,
} from "./purchaseUtils";
import { isFreemiumClinicalRoute } from "@utils/userSegment";
import { routeConstants } from "@utils/constants";
import {
  BrowserSessionState,
  updateSessionState,
} from "src/pageDefinitions/session";

export const redirectToCounterOffer = ({ subscriptionId }) => {
  let counterOfferRedirectURL = `${window.location.origin}/cancel?fcc=US&subscription_id=BMS_${subscriptionId}`;
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get("sha")) {
    counterOfferRedirectURL += `&sha=${urlParams.get("sha")}`;
  }

  window.location.href = counterOfferRedirectURL;
};

export const redirectToTelehealth = async ({
  email,
  isEmailUser,
  glp1Companion,
  planType = "branded",
}: {
  email: string;
  isEmailUser?: boolean;
  glp1Companion?: boolean;
  planType?:
    | BrowserSessionState["selectedMedPlanType"]
    | BrowserSessionState["selectedHRTPlanType"];
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const isMed = ["compounded", "branded", "oralsMetformin"].includes(planType);

  updateSessionState("browser", {
    selectedMedPlanType: undefined,
    selectedHRTPlanType: undefined,
  });

  if (isMed) {
    const data = glp1Companion
      ? getPayloadForGlp1CompanionUser(email)
      : getPayloadForMedUser(email);
    await getStore().dispatch(
      saveSurveyAnswers(data.surveyAnswers, data.params)
    );
    if (!glp1Companion) {
      urlParams.set(
        "route",
        isFreemiumClinicalRoute()
          ? routeConstants.freemiumClinical
          : routeConstants.clinical
      );
    }
    updateSessionState("browser", {
      selectedMedPlanType:
        planType as BrowserSessionState["selectedMedPlanType"],
    });
  } else {
    urlParams.set("standaloneHRTBuyflowEnabledOverwrite", "true");
    urlParams.set("route", routeConstants.hrt);
    await setHRTPreCheckoutData({ email });
    updateSessionState("browser", {
      selectedHRTPlanType:
        planType as BrowserSessionState["selectedHRTPlanType"],
    });
  }

  urlParams.set("fcc", "US");
  urlParams.set("fsub", "AR");
  urlParams.set("idm", "true");
  if (isEmailUser) {
    urlParams.set("utm_source", "MedDrip");
  }

  window.history.pushState({}, "", `${window.location.origin}?${urlParams}`);
  goto.checkout("option_n_20221201_Ex1084var1", true);
};

export async function setHRTPreCheckoutData({ email }: { email: string }) {
  const data = getPayloadForMhtUser(email);
  await getStore().dispatch(
    saveMainSurveyAnswers(data.personaSurveyAnswers, {
      surveyNameSpace: "personaSurveyUS",
    })
  );

  await getStore().dispatch(
    setPurchaseSurveyAnswers(data.paymentSurveyAnswers)
  );

  const urlParams = new URLSearchParams(window.location.search);
  urlParams.set("fcc", "US");
  urlParams.set("fsub", "AR");
  urlParams.set("idm", "true");
  urlParams.set("MhtEnabledOverwrite", "true");
  urlParams.set("enableMhtFlow", "true");

  window.history.pushState({}, "", `${window.location.origin}?${urlParams}`);
}

export const redirectToNWCheckoutWithMHTUser = async ({
  email,
  selectedNoomPlanId,
}: {
  email: string;
  selectedNoomPlanId: string;
}) => {
  await setHRTPreCheckoutData({ email });

  goto.checkout(selectedNoomPlanId);
};

export const redirectToHRTCheckoutPage = async ({
  selectedNoomPlanId,
  planType = "cream",
}: {
  planType?: "cream" | "patch";
  selectedNoomPlanId: string;
}) => {
  const { hrtSurveyAnswers } = getPayloadForHRTCheckout({ planType });

  await getStore().dispatch(setHRTSurveyAnswers(hrtSurveyAnswers));

  const creamRequested = planType === "cream";

  const urlParams = new URLSearchParams(window.location.search);
  urlParams.set("selectHRTCreamPlan", creamRequested.toString());

  window.history.pushState({}, "", `${window.location.origin}?${urlParams}`);

  goto.hrtCheckout(selectedNoomPlanId);
};

const callCoachServerOauth = async (requestData) => {
  const CS_STAGING_OAUTH_URL =
    "https://data-test.noom.com/servlets/oauth/token";
  const requestBody = `${encodeURIComponent("grant_type")}=${encodeURIComponent(
    "password"
  )}&${encodeURIComponent("client_id")}=${encodeURIComponent(
    "noom-coach"
  )}&${encodeURIComponent("request")}=${encodeURIComponent(requestData)}`;

  const requestInit: RequestInit = {
    method: "POST",
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    body: requestBody,
  };
  return fetch(CS_STAGING_OAUTH_URL, requestInit).then(
    async (response) => {
      const text = await response.text();
      let data: JsonObject | string = text;

      try {
        data = JSON.parse(data);
      } catch (e) {
        /* NOP */
      }

      if (response.ok) {
        await setInAppAutoCookie(
          data.accessCode,
          data.access_token,
          data.accountEmail,
          false
        );
        return data;
      }

      throw new Error("Failed response");
    },
    (err) => {
      throw new Error(err);
    }
  );
};

export const createNewUser = async (email, password) => {
  const payload = {
    action: "create_account",
    email,
    userProfileData: {
      email,
      name: "Noom User",
      password,
      phoneNumber: "1234567890",
      language: "en",
      gender: "FEMALE",
      heightInCm: 180,
      weightInKg: 70,
      age: 30,
      isNursing: false,
    },
    password,
  };

  return send("POST", "/api/enrollment/v2/create_account/", payload);
};

export const authenticateUser = async (email, password) => {
  const requestData = `{"mode":"LOGIN","type":"EMAIL_AND_PASSWORD","principal":"${email}","credentials":"${password}"}`;
  return callCoachServerOauth(requestData);
};

export const getInAppUrl = async (
  email: string,
  accessCode: string,
  accessToken: string,
  countryCode: string,
  isAndroid = false,
  isAppEmail = false,
  campaignName = ""
) => {
  function getUrlEndpointRequest() {
    const headers = {
      Authorization: `bearer ${accessToken}`,
    };

    if (isAppEmail) {
      return {
        path: "/internal/sendAppEmail/",
        headers,
        body: {
          access_code: accessCode,
          country: countryCode,
          email,
        },
      };
    }
    return {
      path: "/internal/v2/app/",
      headers,
      body: {
        user_data: {
          gender: "female",
          email,
          country_code: countryCode,
          access_code: accessCode,
          name: "SM Test",
        },
        route_data: {
          trial_duration_days: 14,
          allow_purchase: isAndroid ? "true" : "false",
          sp: "sp",
          utm_source: "appstore",
          fcc: "en",
          ...(campaignName.length > 0 ? { campaign_name: campaignName } : {}),
        },
      },
    };
  }

  const { path, headers, body } = getUrlEndpointRequest();

  return send("POST", path, body, { headers }).then((res) => {
    // Add protocol if missing
    const baseUrl = res.redirect_url || res.url;
    const protocol = baseUrl.includes("http") ? "" : "http://";
    const url = new URL(protocol + baseUrl);

    // On local env the domain/port of the redirect URL needs to be updated to stay on local env
    if (window.location.origin.includes("localhost")) {
      url.protocol = "http";
      url.hostname = "localhost";
      url.port = "8080";
    }

    return url.href;
  });
};

export const useGoToInApp = (
  setStatus: (status: string) => void,
  email: string,
  password: string
) => {
  const [isAndroid, setIsAndroid] = useState(false);
  const toggleIsAndroid = () => setIsAndroid(!isAndroid);

  const [appEmailUrl, setAppEmailUrl] = useState("");

  const [countryCode, setCountryCode] = useState("US");
  const onCountryCodeChange = (e: ChangeEvent<HTMLInputElement>) =>
    setCountryCode(e.target.value);

  async function createInAppUrl(appEmail, campaignName = "") {
    const autologinCookie = getInAppAutoCookie();

    let user = {
      accountEmail: autologinCookie?.email,
      accessCode: autologinCookie?.accessCode,
      access_token: autologinCookie?.accessToken,
    };

    if (!autologinCookie) {
      setStatus("Creating new user...");
      await createNewUser(email, password);
      user = await authenticateUser(email, password);
    }

    setStatus("Fetching in-app URL from growth...");

    return getInAppUrl(
      user.accountEmail,
      user.accessCode,
      user.access_token,
      countryCode,
      isAndroid,
      appEmail,
      campaignName
    );
  }

  async function goToInApp() {
    try {
      const url = await createInAppUrl(false);
      setStatus("Redirecting to in-app...");
      window.location.href = url;
    } catch (e) {
      setStatus("Failed to create in-app URL");
    }
  }

  async function goToAppFree() {
    try {
      const url = await createInAppUrl(false, "freemium_test_campaign");
      setStatus("Redirecting to app-free route...");
      window.location.href = url;
    } catch (e) {
      setStatus("Failed to create in-app URL");
    }
  }

  async function fetchAppEmailUrl() {
    try {
      const url = await createInAppUrl(true);
      setAppEmailUrl(url);
      setStatus("");
    } catch (e) {
      setStatus("Failed to create in-app URL");
    }
  }

  function copyUrlToClipboard() {
    navigator.clipboard.writeText(appEmailUrl);
  }

  return {
    goToInApp: useCallbackRef(goToInApp),
    goToAppFree: useCallbackRef(goToAppFree),
    fetchAppEmailUrl: useCallbackRef(fetchAppEmailUrl),
    copyUrlToClipboard,
    isAndroid,
    appEmailUrl,
    toggleIsAndroid,
    countryCode,
    onCountryCodeChange,
  };
};
