import type { Context } from "@noom/meristem-context";
import { ReadonlyDeep } from "type-fest";
import { routeConstants } from "./constants";
import { logInfo } from "./monitoring/logging";

export type CountryCode =
  | "US"
  | "CA"
  | "IE"
  | "AU"
  | "NZ"
  | "GB"
  | "UK"
  | "DE"
  | "ES"
  | "AT"
  | "KR"
  | "JP"
  | "CH"
  | "ZA"
  | "NL"
  | "AE"
  | "SG"
  | "DK"
  | "IT"
  | "IS"
  | "SE"
  | "IN"
  | "IL"
  | "FI"
  | "PT"
  | "GR"
  | "RO"
  | "BE"
  | "PL"
  | "CZ"
  | "TR"
  | "NO"
  | "FR"
  | "MX";

// eslint-disable-next-line no-underscore-dangle
declare const __GIT_SHA__: string;

export interface RequestMetadata {
  context: Context;
  userIp: string;
  meristemState: MeristemState;
  experimentsCookie: ExperimentDefinition[];
  journey: JourneyDefinition;
  error?: string;
  meristemTrackingProperties: Record<string, string>;
  userState: {
    userId: string;
    newUser: boolean;
    showWelcomeBack: boolean;
  };
  activeConfigurationExperiments: ActiveConfigurationExperimentDefinition[];
}

export interface MeristemState {
  id: number;
  owner: string;
  repo?: string;
  baseline: string;
  description: string;
  experiments: MeristemExperiment[];
}

export interface MeristemExperiment {
  experimentOwner?: string;
  name: string;
  targeting: Targeting[];
  variations: Variation[];
  isGreedyTargeting?: boolean;

  // Not found upstream but referenced in our code
  funnelId?: string;
  startEvents: {
    name: string;
    filters: Targeting[];
  }[];
}

export interface Targeting {
  key: string;
  operator: string;
  value?: string | string[];
}

interface Variation {
  name: string;
  repo?: string;
  sha: string;
  weight: number;
}

export interface ExperimentAndVariation {
  // This name is mapped to experiment.id from meristem state.
  experimentName: string;
  variationName: string;
}

export interface ActiveConfigurationExperimentDefinition
  extends ExperimentAndVariation {
  experimentData: MeristemExperiment;
}

export interface ExperimentDefinition extends ExperimentAndVariation {
  sha: string;
  shaOverride?: string;
  repo?: string;
}

export interface JourneyDefinition {
  [contextType: string]: [
    /** experimentName */ string,
    /** variationName */ string,
    /** visited */ boolean?
  ];
}

export function getSha() {
  return __GIT_SHA__;
}

export function getRequestMetadata() {
  return (window as any).meristemContext as RequestMetadata;
}

/**
 * Returns the meristemContext passed by buyflow-worker, or it fallbacks to default for development environment
 */
export function getMeristemContext(): ReadonlyDeep<Context> {
  const globalMeristemContext = getRequestMetadata();

  if (!globalMeristemContext) {
    // We want to trigger sentry error if the meristem context defaults for the production environment
    throw new Error("MeristemContextDefaulted-UnavailableOnWindow");
  }

  return globalMeristemContext.context;
}

/**
 * Returns user's two character country code (e.g. "US", "GB")
 */
export function getCountryCode() {
  const { country_code } = getMeristemContext();
  return country_code as CountryCode;
}

export function getAppCountryCode() {
  const { app_country_code } = getMeristemContext();
  return app_country_code || getCountryCode();
}

export function getSubdivision() {
  const { subdivision } = getMeristemContext();
  return subdivision;
}

export function getLanguage() {
  const { language_code } = getMeristemContext();
  return language_code || "en";
}

export function getInclusionEU() {
  const { is_eu_citizen } = getMeristemContext();
  return !!is_eu_citizen;
}

export function getCity() {
  const { city_name } = getMeristemContext();
  return city_name;
}

export function getPostalCode() {
  const { postal_code } = getMeristemContext();
  return postal_code;
}

/**
 * Returns user's current locale (i.e. en-US)
 * Returns 'pure' language code if country not found (en)
 */
export function getLocale(): string {
  const languageCode = getLanguage();
  const countryCode = getCountryCode();
  if (!countryCode) {
    return languageCode.toLowerCase();
  }
  return `${languageCode.toLowerCase()}-${countryCode.toUpperCase()}`;
}

export function getActiveConfigurationExperiments(): ReadonlyDeep<
  ActiveConfigurationExperimentDefinition[]
> {
  return getRequestMetadata().activeConfigurationExperiments || [];
}

export function getExperimentState(): ReadonlyDeep<ExperimentDefinition[]> {
  return getRequestMetadata().experimentsCookie || [];
}

export function getExperimentsJourney(): ReadonlyDeep<JourneyDefinition> {
  return getRequestMetadata().journey || {};
}

export function getMeristemState(): ReadonlyDeep<MeristemState> {
  return getRequestMetadata().meristemState;
}

export function setContextType(context: string) {
  logInfo(`Setting context type to ${context}`);
  const globalMeristemContext = getRequestMetadata();
  globalMeristemContext.context.context_type = context;
}

export function getRouteId(): routeConstants {
  return (
    (getMeristemContext()?.route_id as routeConstants) || routeConstants.direct
  );
}

export function getHmRouteId(currentRoute: string = getRouteId()) {
  let newRoute = currentRoute;
  if (newRoute === routeConstants.direct) {
    newRoute = routeConstants.hmDirect;
  } else if (!newRoute.startsWith("hm")) {
    newRoute = `hm-${newRoute}`;
  }
  return newRoute;
}
