import type { Event, EventHint } from "@sentry/types";
import { appConfig } from "src/config";
import { JsonScalar } from "src/pageDefinitions/values";
import invariant from "tiny-invariant";
import { trackEvent } from "./api/tracker";
import { NoomError } from "./error/NoomError";
import { prepareGrowthAPIParameters } from "./services/api-params";
import { stripURLParams } from "./urlParams";
import { isUnloading } from "./lifecycle";
import { awaitableSleep } from "./timing";
import { QueryClient } from "react-query";
import getStore from "./redux/store";
import {
  telehealthLoginRedirect,
  getAuthHeader,
} from "@utils/authCookieParser";

type SendOptions = {
  params?: URLSearchParams;
  headers?: Record<string, JsonScalar>;
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000,
    },
  },
});

/*
 * A wrapper function for Telehealth Growth endpoints which require authentication
 * Will handle 401/403 and redirect to a login page
 */
export async function telehealthSend(
  method: "GET" | "POST" | "PATCH" = "GET",
  pathname: string | URL,
  body?: JsonObject | URLSearchParams | FormData,
  sendOptions: SendOptions = {},
  forceLogin = true
) {
  try {
    return await send(method, pathname, body, {
      ...sendOptions,
      ...{ headers: getAuthHeader(forceLogin) },
    });
  } catch (e) {
    if (
      e &&
      e.response &&
      (e.response.status === 403 || e.response.status === 401)
    ) {
      telehealthLoginRedirect();
    }

    throw e;
  }
}

/**
 * Convenience method that wraps Axios and generates a request for us with some
 * preset settings.
 */
export function send<T extends JsonObject>(
  method: "GET" | "POST" | "PATCH" = "GET",
  pathname: string | URL,
  body?: JsonObject | URLSearchParams | FormData,
  { params, headers }: SendOptions = {}
): Promise<T> {
  const url = buildURL(pathname, params);
  let encodedBody = body;
  let contentType;
  if (body) {
    if (body instanceof URLSearchParams) {
      contentType = "application/x-www-form-urlencoded;charset=utf-8";
    } else if (!(body instanceof FormData)) {
      contentType = "application/json";
      encodedBody = JSON.stringify(body);
    }
  }

  const requestInit: RequestInit = {
    method,
    headers: {
      accept: "application/json",
      ...(contentType && { "content-type": contentType }),
      ...headers,
    },
    credentials: "include",
    body: encodedBody || undefined, // null will error
  };

  return fetch(url, requestInit).then(
    async (response) => {
      const text = await response.text();
      let data: JsonObject | string = text;

      try {
        data = JSON.parse(data);
      } catch (e) {
        /* NOP */
      }

      if (response.ok) {
        return data;
      }

      throw new FailedResponse(method, url, body, response, data);
    },
    async (err) => {
      // Delay handling errors slightly to see if this error is the result
      // of a page unload. At time of writing, Firefox and Safari will tear
      // down the connections and then emit pagehide. Chrome the opposite.
      await awaitableSleep(250);

      if (isUnloading()) {
        throw new PageUnloadedError(method, url, err);
      }

      const message = `Fetch error: ${method}:${stripURLParams(url)}`;
      const toThrow = new NoomError(message, {
        fingerprint: [message],
        cause: err,
      });
      toThrow.trackMetric = () => {
        trackEvent("OnNetworkError", {
          method,
          requestURL: stripURLParams(url),
          status: "fetch-failed",
          error: err.message,
        });
        return true;
      };
      throw toThrow;
    }
  );
}

export function sendBeacon<T extends JsonObject>(
  pathAndQuery: string,
  body: T
) {
  invariant(pathAndQuery.startsWith("/"), "Path must start with /");

  try {
    /*
     * Possible race condition here, since the beacon response can include cookies, and will attempt
     * to clear the session cookie if invalid. However, the userId cookie and the session cookie are
     * set by the loading of the document. THis is what I think is happening:
     * When the session is invalid, a beacon could be started right before we reload the page, but
     * not return until after the reload is complete (normal requests would've been cancelled by the
     * browser). The reload of the document sets the new cookies, but the beacon response will
     * immediately invalidate them. And we send a beacon before every page refresh, since we are
     * watching for visibilitychange to send mixpanel events when they close the tab.
     *
     * So to handle this, don't send any beacons if we already know we have an invalid session.
     */
    const { authStatus } = getStore().getState();
    if (authStatus.sessionInvalid) {
      return Promise.resolve();
    }
  } catch (e) {
    /* NOP */
  }

  const url = buildURL(pathAndQuery, prepareGrowthAPIParameters());

  try {
    if (navigator.sendBeacon(url, JSON.stringify(body))) {
      return Promise.resolve();
    }
  } catch (e) {
    /* NOP */
  }

  return send("POST", url, body).catch(() => {
    /* NOP: Reporting this can result in an infinite loop of network connections */
  });
}

type DateRequest = {
  method: string;
  url: string;
  body?: JsonObject | URLSearchParams;
};

/**
 * Indicated a given request has failed due to the page being unloaded while the
 * request was in flight.
 */
class PageUnloadedError extends NoomError {
  public readonly request: DateRequest;

  constructor(method: string, url: string, cause?: Error) {
    super("PageUnloadedError", {
      cause,
    });

    this.request = {
      method,
      url,
    };
  }
  override trackMetric() {
    trackEvent("OnNetworkError", {
      method: this.request.method,
      requestURL: this.request.url,
      status: "page-unloaded",
    });
    return true;
  }
}

export class FailedResponse extends NoomError {
  public readonly request: DateRequest;
  public readonly response?: Response;
  public readonly responseData?: JsonObject | string;

  constructor(
    method: string,
    url: string,
    requestBody: JsonObject | URLSearchParams,
    response: Response,
    responseData: JsonObject | string
  ) {
    const resource = `${method} ${url.replace(/\?.*/, "")}`;
    // Note we can not include the url in this portion to avoid
    // sentry filtering. We'll add it in post.
    const message = `FailedResponse`;

    super(message, {
      tags: {
        resource,
        status: `${response.status}`,
      },
      fingerprint: [resource],
    });

    this.request = {
      method,
      url,
      body: requestBody,
    };

    // NOTE(imran): We'd normally have the data as part of the response object,
    // but Response is a native object type with browser-dependent behavior
    // and we don't necessarily want to go mutating `fetch`'s response
    this.response = response;
    this.responseData = responseData;
  }
  override trackMetric() {
    const status = this.response?.status;

    if (!status || (status >= 400 && status < 500)) {
      trackEvent("OnNetworkError", {
        method: this.request.method,
        requestURL: this.request.url,
        status,
      });
      return true;
    }

    return false;
  }
  override extendSentry(event: Event, hint: EventHint) {
    super.extendSentry(event, hint);

    function last<T>(arr: T[]) {
      return arr[arr.length - 1];
    }
    const exception = last(event.exception.values);

    // Expand the message sent to sentry to provide above fold
    // visibility of the failing endpoint.
    if (exception.value === this.message) {
      exception.value = `${this.message} ${this.request.method}:${this.request.url} ${this.response?.status}`;
    }

    // eslint-disable-next-line no-param-reassign
    event.contexts.Request = this.request;
  }
}

function buildURL(hrefOrPathname: string | URL, params?: URLSearchParams) {
  let url: URL;
  if (typeof hrefOrPathname !== "string") {
    url = hrefOrPathname;
  } else if (hrefOrPathname.includes("//")) {
    url = new URL(hrefOrPathname);
  } else {
    url = new URL(
      `${appConfig.API_DOMAIN}/${hrefOrPathname.replace(/^\//, "")}`,
      window.location.href
    );
  }

  if (params) {
    for (const param of params) {
      url.searchParams.set(param[0], param[1]);
    }
  }

  return `${url}`;
}
