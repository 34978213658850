import { calculateTargetMonthFromDate } from "src/choosePlan/components/plans/Plans";
import { getSurveyAnswers } from "src/hooks/survey/answers";
import { calculateBMI } from "src/utils/calculateGoals";
import { getPersonalizedTargetDate } from "src/utils/pace";
import { getIndexFromGraphId } from "src/utils/pace/graphIndex";
import { savePlanToBraze } from "src/utils/plans";
import getStore from "src/utils/redux/store";
import { isEligibleForStandaloneHRTBuyflow } from "@utils/userSegment/features";

export const saveRecommendedPlanToCRM = async () => {
  const state = getStore().getState();
  const surveyAnswers = getSurveyAnswers(state);
  const { currentBMI, targetBMI } = calculateBMI(surveyAnswers);
  const { recommendedPlan } = state;

  const graphId = isEligibleForStandaloneHRTBuyflow() ? "hrtOptIn" : "medOptIn";
  const targetDate = getPersonalizedTargetDate(getIndexFromGraphId(graphId));
  const { targetMonthNameFull } = calculateTargetMonthFromDate(targetDate);

  await savePlanToBraze({
    currentBMI,
    targetBMI,
    targetMonthNameFull,
    recommendedPlan,
  });
};
