import React from "react";
import styled from "@emotion/styled";

import { Text } from "@components/core/Text";

import { fontP3Regular, fontTitle } from "src/design-system/styles/fonts";
import { compassColors } from "src/utils/styling";

const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 345px;
  margin: 0 auto;
  align-items: center;
  border-radius: 16px;
  border: 1px solid ${compassColors.sand1};
  background-color: ${compassColors.white};
`;

const TitleSection = styled.div`
  ${fontTitle};
  padding: 8px 12px;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  background-color: ${compassColors.conch};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

const Content = styled.div`
  ${fontP3Regular};
  padding: 12px;
  text-align: center;
`;

type Props = {
  baseTextKey: string;
};

export function MenopauseSupportBox({ baseTextKey }: Props) {
  return (
    <BoxContainer>
      <TitleSection>
        <Text i18nKeyOrText={`t:${baseTextKey}:menopauseBoxTitle`} />
      </TitleSection>
      <Content>
        <Text i18nKeyOrText={`t:${baseTextKey}:menopauseBoxText`} />
      </Content>
    </BoxContainer>
  );
}
